import React, { useContext, useState, useEffect } from "react";
import { authService } from "../../services/authService";
import AuthContext from "../../contexts/AuthContext";
import { Link } from "react-router-dom";
import Errors from "../shared/Errors";
import { getErrorMessage } from "../../utils/commonMethods";
import FacebookLoginButton from "./FacebookLoginButton";
import GoogleLoginButton from "./GoogleLoginButton";

const RegistrationModal = ({ show, onClose, onRegistrationComplete }) => {
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [genderRaw, setGenderRaw] = useState("");
  const [activeTab, setActiveTab] = useState("register");
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isFacebookApp, setIsFacebookApp] = useState(false);

  const { setIsAuthenticated, setIsAdmin } = useContext(AuthContext);

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (userAgent.includes("FBAN") || userAgent.includes("FBAV")) {
      setIsFacebookApp(true);
    }
  }, []);

  const handleRegistration = async (e) => {
    e.preventDefault();

    if (!termsAccepted) {
      return;
    }

    try {
      const gender = genderRaw ? parseInt(genderRaw, 10) : null;
      await authService.register(
          name,
          surname,
          email,
          password,
          gender,
          setIsAuthenticated,
          setIsAdmin
      );
      onRegistrationComplete();
      onClose();
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      setErrorMessage(errorMessage);
      setShowErrors(true);
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await authService.login(email, password, setIsAuthenticated, setIsAdmin);
      onRegistrationComplete();
      onClose();
    } catch (error) {
      console.error("Error logging in:", error);
    }
  };

  const switchToRegisterTab = () => {
    setActiveTab("register");
  };

  const switchToLoginTab = () => {
    setActiveTab("login");
  };

  return (
      <div className={show ? "registration-modal active" : "registration-modal"}>
        <Errors
            show={showErrors}
            message={errorMessage}
            onClose={() => setShowErrors(false)}
        />
        <div className="registration-modal-content">
          <h2>Registration</h2>
          <p className="registration-free-message">
            It's free, so don't worry about any charges!
          </p>
          <div className="registration-tabs">
            <button
                className={activeTab === "register" ? "active" : ""}
                onClick={switchToRegisterTab}
            >
              Register
            </button>
            <button
                className={activeTab === "login" ? "active" : ""}
                onClick={switchToLoginTab}
            >
              Login
            </button>
          </div>
          <div className="tab-content">
            {activeTab === "register" && (
                <form className="registration-form" onSubmit={handleRegistration}>
                  <div>
                    <label>Name</label>
                    <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div>
                    <label>Surname</label>
                    <input
                        type="text"
                        value={surname}
                        onChange={(e) => setSurname(e.target.value)}
                    />
                  </div>
                  <div>
                    <label>Email</label>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        autoComplete="username"
                    />
                  </div>

                  <div>
                    <label>Password</label>
                    <input
                        type="password"
                        autoComplete="current-password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  <label>Gender</label>
                  <select
                      value={genderRaw}
                      onChange={(e) => setGenderRaw(e.target.value)}
                      className="registration-form-input"
                  >
                    <option value="">Select Gender</option>
                    <option value="1">Male</option>
                    <option value="2">Female</option>
                    <option value="3">Other</option>
                  </select>
                  <div className="registration-terms-container">
                    <input
                        type="checkbox"
                        id="terms"
                        checked={termsAccepted}
                        onChange={() => setTermsAccepted(!termsAccepted)}
                    />
                    <label htmlFor="terms">
                      I accept the{" "}
                      <Link to="/terms-and-conditions">Terms and Conditions</Link>
                    </label>
                  </div>
                  <div className="registration-buttons">
                    <button type="submit" disabled={!termsAccepted}>
                      Register
                    </button>
                    <button type="button" onClick={onClose}>
                      Cancel
                    </button>
                  </div>
                  {!isFacebookApp && (
                      <>
                        <FacebookLoginButton
                            setIsAuthenticated={setIsAuthenticated}
                            setIsAdmin={setIsAdmin}
                            onSuccess={() => {
                              onRegistrationComplete();
                              onClose();
                            }}
                            navigateOnSuccess={false}
                            className="registration-facebook-button"
                        />
                        <GoogleLoginButton
                            setIsAuthenticated={setIsAuthenticated}
                            setIsAdmin={setIsAdmin}
                            onSuccess={() => {
                              onRegistrationComplete();
                              onClose();
                            }}
                            navigateOnSuccess={false}
                            className="registration-google-button"
                        />
                      </>
                  )}
                </form>
            )}
            {activeTab === "login" && (
                <form className="registration-form" onSubmit={handleLogin}>
                  <div>
                    <label>Email</label>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        autoComplete="username"
                    />
                  </div>

                  <div>
                    <label>Password</label>
                    <input
                        type="password"
                        autoComplete="current-password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  <div className="registration-buttons">
                    <button type="submit">Login</button>
                    <button type="button" onClick={onClose}>
                      Cancel
                    </button>
                  </div>
                  {!isFacebookApp && (
                      <>
                        <FacebookLoginButton
                            setIsAuthenticated={setIsAuthenticated}
                            setIsAdmin={setIsAdmin}
                            onSuccess={() => {
                              onRegistrationComplete();
                              onClose();
                            }}
                            navigateOnSuccess={false}
                            className="registration-facebook-button"
                        />
                        <GoogleLoginButton
                            setIsAuthenticated={setIsAuthenticated}
                            setIsAdmin={setIsAdmin}
                            onSuccess={() => {
                              onRegistrationComplete();
                              onClose();
                            }}
                            navigateOnSuccess={false}
                            className="registration-google-button"
                        />
                      </>
                  )}
                </form>
            )}
          </div>
        </div>
      </div>
  );
};

export default RegistrationModal;

