import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { blogService } from "../../services/blogService";
import {formatDate, getErrorMessage, trimText} from "../../utils/commonMethods";
import LoadingModal from "../shared/Loading";

const Blog = () => {
  const [posts, setPosts] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [searchTerm, setSearchTerm] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const navigate = useNavigate();

  // useEffect(() => {
  //   const fetchPosts = async () => {
  //     try {
  //       setIsLoading(true)
  //       const data = await blogService.getBlogPosts(currentPage, 10); // Adjust the second parameter for the desired page size
  //       console.log(data)
  //       setPosts(data.blogs);
  //       setTotalPages(data.total_pages);
  //     } catch (error) {
  //       const errorMessage = getErrorMessage(error);
  //       setError(errorMessage);
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   };
  //
  //   fetchPosts();
  // }, [currentPage]);

  useEffect(() => {
    const fetchPosts = async () => {
      setIsLoading(true);
      try {
        const data = await blogService.getBlogPosts(currentPage, 10, searchQuery);
        setPosts(data.blogs);
        setTotalPages(data.total_pages);
      } catch (error) {
        setError(getErrorMessage(error));
      } finally {
        setIsLoading(false);
      }
    };

    fetchPosts();
  }, [currentPage, searchQuery]); // Now depends on searchQuery

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearchKeyPress = (e) => {
    if (e.key === 'Enter') {
      setSearchQuery(searchTerm);
      setCurrentPage(1); // Optionally reset to the first page on new search
    }
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  if (isLoading) {
    return <LoadingModal show={isLoading} />;
  }

  if (error) return <p>Error: {error.message}</p>;

  const viewBlogOnClick = (id) => {
    sessionStorage.setItem('currentPage', currentPage.toString());
    navigate(`/blogs/${id}`);
  };

  return (
    <div>
      <div className="initial-fade box">
        <h1 className="special-h1 initial-fade">Blog</h1>
        {/*<div className="search-bar">*/}
        {/*  <input*/}
        {/*      type="text"*/}
        {/*      placeholder="Search blogs..."*/}
        {/*      value={searchTerm}*/}
        {/*      onChange={handleSearch}*/}
        {/*  />*/}
        {/*</div>*/}
        <div className="search-bar">
          <input
              type="text"
              placeholder="Search blogs..."
              value={searchTerm}
              onChange={handleSearchChange}
              onKeyPress={handleSearchKeyPress} // Listen for key presses
          />
        </div>
        <ul className="post-list">
          {posts &&
            posts.map((post) => (
              <li className="post-container" key={post.id}>
                <div className="blog-card">
                  <img
                    src={post.image_or_video_url}
                    alt="cover-image"
                    className="blog-cover-image"
                  />
                  <div className="blog-details">
                    <h2 className="blog-title">{post.title}</h2>
                    <p className="blog-subtitle">{trimText(post.short_content, 150)}</p>
                    <div className="date-likes">
                      <p className="blog-date">
                        {/*{post.created_on}*/}
                        {formatDate(post.created_on)}
                      </p>
                    </div>
                  </div>
                  <button onClick={() => viewBlogOnClick(post.id)}>View</button>
                </div>
              </li>
            ))}
        </ul>
      </div>
      <div>
        <button onClick={() => handlePageChange(currentPage - 1)}>
          Previous
        </button>
        <span>{`${currentPage} of ${totalPages}`}</span>
        <button onClick={() => handlePageChange(currentPage + 1)}>Next</button>
      </div>
    </div>
  );
};

export default Blog;
