import React from 'react';
import {LOGO} from "../utils/constants";


const Contacts = () => {
    const fadeClass = 'initial-fade'; // Static class for fade-in effect

    return (
        <div className="container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <h2 className={`special-h1 ${fadeClass}`} style={{ textAlign: 'center' }}>Contact Us</h2>
            <h2 style={{ textAlign: 'center' }}>Email: office@beautiz.io</h2>
            <div className="intro-section initial-fade" style={{ display: 'flex', justifyContent: 'center' }}>
                <img src={LOGO} alt="Logo" className="header-logo"/>
            </div>
        </div>
    );
};

export default Contacts;
