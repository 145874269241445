import { requestInterceptor } from './requestInterceptor';

const serverUrl = process.env.REACT_APP_SERVER_URL;

export const imageService  = {
    baseUrl: serverUrl,

    async uploadImage(file) {
        const formData = new FormData();
        formData.append('imageFiles', file);

        try {
            const request = requestInterceptor();
            const response = await request.post(`${this.baseUrl}/upload`, formData);

            return response.data;

        } catch (error) {
            throw error;
        }
    },

    async uploadMultipleImages(blobs) {
        const formData = new FormData();
        blobs.forEach((blob, index) => {
            formData.append('imageFiles', blob, 'captured' + index + '.png');
        });
        try {
            const request = requestInterceptor();
            const response = await request.post(`${this.baseUrl}/upload`, formData);

            return response.data;

        } catch (error) {
            throw error;
        }
    },

    async saveProduct(productAdvice, productName) {
        try {
            const {
                id,
                enhancement, contraindications, routine, userId } = productAdvice;
            const data = {
                id,
                name: productName, enhancement, contraindications, routine, isSaved: true, userId
            };
            const request = requestInterceptor();
            const saveResponse = await request.post(`${this.baseUrl}/saveProduct`, data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            return saveResponse.data;
        } catch (error) {
            throw error;
        }
    },
};


