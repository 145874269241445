import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom'; // Import useNavigate for redirection
import { authService } from "../../services/authService";
import { getErrorMessage } from "../../utils/commonMethods";

const PasswordReset = () => {
    const [newPassword, setNewPassword] = useState('');
    const [token, setToken] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [resetSuccess, setResetSuccess] = useState(false); // State to track reset success
    const [searchParams] = useSearchParams();
    const navigate = useNavigate(); // For redirecting to the login page

    useEffect(() => {
        const tokenParam = searchParams.get('token');
        if (tokenParam) {
            setToken(tokenParam);
        }
    }, [searchParams]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(''); // Clear any existing error messages
        setMessage(''); // Clear any existing messages
        if (newPassword.length < 6) {
            setError("Password must be at least 6 characters long.");
            return;
        }

        try {
            await authService.resetPassword(token, newPassword);
            setMessage("Password reset successfully.");
            setResetSuccess(true); // Indicate that the reset was successful
            setNewPassword('');
        } catch (error) {
            const errorMessage = getErrorMessage(error);
            setMessage(errorMessage);
        }
    };

    const handleLoginRedirect = () => {
        navigate('/login');
    };

    return (
        <form onSubmit={handleSubmit} className="form box initial-fade">
            <label>New Password</label>
            <input type="password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} required />
            <button type="submit">Reset Password</button>
            {error && <p style={{ color: "red" }}>{error}</p>}
            {message && <p>{message}</p>}
            {resetSuccess && (
                <button type="button" onClick={handleLoginRedirect}>
                    Login
                </button>
            )}
        </form>
    );
};

export default PasswordReset;

