import React, { useContext } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import googleService from "../../services/googleService";
import AuthContext from "../../contexts/AuthContext";
import { useNavigate } from 'react-router-dom';

const GoogleLoginButton = ({ onSuccess, onFailure, navigateOnSuccess = false, targetRoute = '/' }) => {
    const { setIsAuthenticated, setIsAdmin } = useContext(AuthContext);
    const navigate = useNavigate(); // Using useNavigate hook from React Router for navigation

    const login = useGoogleLogin({
        onSuccess: async (tokenResponse) => {
            try {

                await googleService.authenticateWithToken(tokenResponse.access_token, setIsAuthenticated, setIsAdmin);

                if (onSuccess) {
                    onSuccess();
                }

                if (navigateOnSuccess) {
                    navigate(targetRoute);
                }
            } catch (error) {
                console.error('Authentication with backend failed:', error);
                if (onFailure) {
                    onFailure(error);
                }
            }
        },
        onError: (error) => {
            console.error('Google login error:', error);
            // Call onFailure callback if provided
            if (onFailure) {
                onFailure(error);
            }
        }
    });

    return <button class="login-with-google-btn" onClick={() => login()}>
        <div className="google-icon"></div>
        Sign in with Google
    </button>;
};

export default GoogleLoginButton;

