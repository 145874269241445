import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { blogService } from "../../services/blogService";
import {getErrorMessage, trimText} from "../../utils/commonMethods";

const SingleBlogPost = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [post, setPost] = useState(null);
  const [latestPosts, setLatestPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBlogPost = async () => {
      try {
        const data = await blogService.getBlogPostById(id);
        setPost(data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogPost();
    fetchLatestPosts();
  }, [id]);

  const fetchLatestPosts = async () => {
    try {
      // Assuming there's a method to fetch the latest posts in your blog service
      const latestData = await blogService.getLatestPosts(3, id);
      setLatestPosts(latestData.blogs);
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      setError(errorMessage);
    }
  };

  const handleBackButtonClick = () => {
    // Optionally, retrieve the last visited page number from storage
    const lastPage = sessionStorage.getItem('currentPage') || 1;
    navigate(`/blogs?page=${lastPage}`);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;
  if (!post) return <p>Blog post not found.</p>;

  return (
      <main className="blog-post-content">
        <h1>{post.title}</h1>
        <img src={post.image_or_video_url} alt="cover" className="blog-post-image"/>
        <div dangerouslySetInnerHTML={{ __html: post.content }} />
        <div className="latest-posts">
          <h2>Latest Articles</h2>
          {latestPosts.map((latestPost) => (
              <Link key={latestPost.id} to={`/blogs/${latestPost.id}`}>
                <article>
                  <h3>{latestPost.title}</h3>
                  <img
                      src={latestPost.image_or_video_url}
                      alt="cover-image"
                      className="blog-cover-image"
                  />
                  <p>{trimText(latestPost.short_content, 150)}</p>
                </article>
              </Link>
          ))}
        </div>
        <button onClick={handleBackButtonClick}>Back to Blogs</button>
      </main>
  );
};

export default SingleBlogPost;
