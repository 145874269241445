import React, { useContext, useEffect, useRef, useState } from "react";
import { imageService } from "../../services/imagesService";
import { authService } from "../../services/authService";
import RegistrationModal from "../auth/RegistrationModal";
import AuthContext from "../../contexts/AuthContext";

import LoadingModal from "../shared/Loading";
import SuccessMessage from "../shared/SuccessMessage";
import Errors from "../shared/Errors";
import AdviceModal from "./AdviceModal";

import {getErrorMessage} from "../../utils/commonMethods";

const UploadImage = () => {
  const imageRef = useRef(null);

  const [productAdvice, setProductAdvice] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedImages, setSelectedImages] = useState([]);
  const [uploadQueue, setUploadQueue] = useState([]);

  const [showRegistrationModal, setShowRegistrationModal] = useState(false);
  const [registrationCompleted, setRegistrationCompleted] = useState(false);
  const { isAuthenticated, setIsAuthenticated } = useContext(AuthContext);

  const [uploadMode, setUploadMode] = useState(null);

  const [showAdviceModal, setShowAdviceModal] = useState(false);

  const singleFileInputRef = useRef(null);
  const multipleFileInputRef = useRef(null);

  const [productAds, setProductAds] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [showMessage, setShowMessage] = useState(false);

  const [showErrors, setShowErrors] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const generateUniqueName = (originalName) => {
    const timestamp = Date.now();
    const randomSegment = Math.floor(Math.random() * 1000); // Random number between 0 to 999
    const extension = originalName.split('.').pop(); // Get the file extension
    return `image-${timestamp}-${randomSegment}.${extension}`;
  };

  const clearSelectedFile = () => {
    // Clear the selectedFile state
    setSelectedFile(null);

    // Reset the file input value (to clear the selected file)
    if (singleFileInputRef.current) singleFileInputRef.current.value = "";

    // Hide the image and clear its src
    if (imageRef.current) {
      imageRef.current.src = "";
      imageRef.current.style.display = "none";
    }

    // Reset the upload mode
    setUploadMode(null);
  };

  const validateAuthentication = () => {
    const token = authService.getToken();
    if (!isAuthenticated && !token) {
      setShowRegistrationModal(true); // Make sure this line is here
      return false;
    }
    return true;
  };

  const styles = {
    image: {
      maxHeight: "400px",
      maxWidth: "400px",
    },
  };

  const handleShowAdviceModal = () => {
    setShowAdviceModal(true);
  };

  const handleCloseAdviceModal = async () => {
    setShowAdviceModal(false);
    setProductAdvice(null);
    clearSelectedFile();
    clearAllSelectedImages();
  };

  const handleRegistration = () => {
    setRegistrationCompleted(true);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const dataURL = URL.createObjectURL(file);
    const uniqueName = generateUniqueName(file.name);
    const fileWithUniqueName = new File([file], uniqueName, { type: file.type });
    imageRef.current.src = dataURL;
    imageRef.current.style.display = "block";
    setSelectedFile(fileWithUniqueName);

    setUploadMode("single");
  };

  const uploadFile = async () => {
    if (selectedFile) {
      if (!validateAuthentication()) {
        return;
      }

      try {
        setIsLoading(true);
        const response = await imageService.uploadImage(
          // URL.createObjectURL(selectedFile)
            selectedFile
        );
        setProductAdvice(response.product_advice);

        if (response.product_ads && Array.isArray(response.product_ads)) {
          setProductAds(response.product_ads);
        }
        setIsLoading(false);
      } catch (error) {

        const errorMessage = getErrorMessage(error);
        setErrorMessage(errorMessage);
        setShowErrors(true);
        setIsLoading(false);
      }
    }
  };

  const handleMultipleFilesUpload = async () => {
    if (!validateAuthentication()) {
      return;
    }

    if (uploadQueue.length <= 1) {
      setErrorMessage("Please select more than one image for multiple uploads.");
      setShowErrors(true);
      return;
    }

    if (uploadQueue.length >2){
      setErrorMessage("You can only upload up to 2 images at a time.");
      setShowErrors(true);
      return;
    }

    setIsLoading(true);

    try {
      const response = await imageService.uploadMultipleImages(uploadQueue);
      setProductAdvice(response.product_advice);
      if (response.product_ads && Array.isArray(response.product_ads)) {
        setProductAds(response.product_ads);
      }
      setUploadQueue([]);
      setIsLoading(false);
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      setErrorMessage(errorMessage)
      setShowErrors(true);
      setIsLoading(false);
    }
  };

  const handleMultipleFilesSelection = (event) => {
    const files = Array.from(event.target.files).map((file) => {
      const uniqueName = generateUniqueName(file.name);
      // Create a new File object with the unique name for each file
      return new File([file], uniqueName, { type: file.type });
    });
    const newSelectedImages = files.map((file) => URL.createObjectURL(file));

    // Add the new images to the existing ones
    setSelectedImages((prevImages) => [...prevImages, ...newSelectedImages]);
    setUploadQueue((prevFiles) => [...prevFiles, ...files]);

    setUploadMode("multiple");
  };

  const handleRemoveImage = (index) => {
    // Remove the image from selectedImages and uploadQueue states
    setSelectedImages((prevImages) => prevImages.filter((_, i) => i !== index));
    setUploadQueue((prevFiles) => prevFiles.filter((_, i) => i !== index));

    // Clear the input's value to allow for re-uploads
    if (multipleFileInputRef.current) multipleFileInputRef.current.value = "";
  };

  const clearAllSelectedImages = () => {
    setSelectedImages([]);
    setUploadQueue([]);
    // Clear the input's value to allow for re-uploads
    if (multipleFileInputRef.current) multipleFileInputRef.current.value = "";
  };

  const handleSaveSuccess = () => {
    setShowMessage(true); // Show the success message
    setTimeout(() => setShowMessage(false), 3000); // Optionally hide the message after some time
  };



  useEffect(() => {
    if (registrationCompleted) {
      switch (uploadMode) {
        case "single":
          uploadFile();
          break;
        case "multiple":
          handleMultipleFilesUpload();
          break;
        default:
          break;
      }
    }
  }, [registrationCompleted, uploadMode]);

  useEffect(() => {
    if (productAdvice) {
      handleShowAdviceModal();
    }
  }, [productAdvice]);

  useEffect(() => {
    if (!showAdviceModal) {
      setProductAdvice(null);
    }
  }, [showAdviceModal]);

  return (
    <main className="upload-image-container">
      <Errors
        show={showErrors}
        message={errorMessage}
        onClose={() => setShowErrors(false)}
      />
      <LoadingModal show={isLoading} />
      {showMessage && (
        <SuccessMessage
          message="Advice was saved to your collections!"
          duration={3000}
          onDismiss={() => setShowMessage(false)}
        />
      )}
      <RegistrationModal
        className="modal"
        show={showRegistrationModal}
        onClose={() => setShowRegistrationModal(false)}
        onRegistrationComplete={handleRegistration}
      />
      <AdviceModal
          show={showAdviceModal}
          onClose={handleCloseAdviceModal}
          productAdvice={productAdvice}
          productAds={productAds}
          onSaveSuccess={handleSaveSuccess}
      />
      <>
        <div className="initial-fade">
          <div className="h1-container">
            <h1>Get Beauty Advice</h1>
          </div>
          <div className="file-upload mb-3 box">
            <h3>Single Product</h3>
            <p>Upload a picture of your product's ingredients label to get Advice.</p>
            <div className="file-btn-wrapper">
              <button>Select Image</button>
              <input
                ref={singleFileInputRef}
                type="file"
                className="file-input"
                id="imageFile"
                name="imageFile"
                accept="image/*"
                onChange={handleFileUpload}
              />
            </div>
            <img
              ref={imageRef}
              className="mt-3 img-fluid"
              style={{ ...styles.image, display: "none" }}
              alt="Selected"
            />
            {selectedFile && (
              <>
                <button onClick={uploadFile}>Upload</button>
                <button onClick={clearSelectedFile}>Delete</button>
              </>
            )}
          </div>
          <div className="file-upload mb-3 box">
            <h3>Multiple Products</h3>
            <p>Upload pictures of two of your products' ingredients labels to get Compatability Score & Advice.</p>
            <div className="file-btn-wrapper">
              <button>Select Images</button>
              <input
                type="file"
                multiple
                className="file-input"
                id="multipleImageFiles"
                name="multipleImageFiles"
                accept="image/*"
                onChange={handleMultipleFilesSelection}
              />
            </div>
            <div className="selected-images">
              {selectedImages.map((src, index) => (
                <div
                  key={index}
                  style={{
                    position: "relative",
                    display: "inline-block",
                    marginRight: "10px",
                  }}
                >
                  <button
                    onClick={() => handleRemoveImage(index)}
                    style={{
                      position: "absolute",
                      top: "5px",
                      right: "5px",
                      background: "red",
                      color: "white",
                      borderRadius: "50%",
                      padding: "0 5px",
                      border: "none",
                      cursor: "pointer",
                    }}
                  >
                    x
                  </button>
                  <img
                    src={src}
                    className="mt-3 img-fluid"
                    style={styles.image}
                    alt={`Selected ${index}`}
                  />
                </div>
              ))}
            </div>
            {uploadQueue.length > 0 && (
              <button onClick={handleMultipleFilesUpload}>Upload</button>
            )}
          </div>
        </div>
      </>
    </main>
  );
};

export default UploadImage;
