import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const aicosmetics = 'https://imagedelivery.net/2vHchWmxdOPj_x0tJa3sgw/898f8df4-6321-40f9-aa2b-fa9b2aad1900/public';
const snapshot = 'https://imagedelivery.net/2vHchWmxdOPj_x0tJa3sgw/8d915f43-ff9e-4287-8c74-af808f4aa700/public';
const score = 'https://imagedelivery.net/2vHchWmxdOPj_x0tJa3sgw/0fd63ad2-6a94-47d1-3901-6d61f82b4000/public';
const enhancements = 'https://imagedelivery.net/2vHchWmxdOPj_x0tJa3sgw/ed218fbe-3774-4b1c-d548-c26008690900/public';
const contraindications = 'https://imagedelivery.net/2vHchWmxdOPj_x0tJa3sgw/6b1d89ce-8637-49df-ce84-5d38a7437e00/public';
const routine = 'https://imagedelivery.net/2vHchWmxdOPj_x0tJa3sgw/e483e977-0941-44f7-73ea-0f6e0ef4ab00/public';
const save = 'https://imagedelivery.net/2vHchWmxdOPj_x0tJa3sgw/c18873bf-8d69-49ad-2cdd-9b3814c7c600/public';

const HomePage = () => {
    const [initialLoad, setInitialLoad] = useState(true);

    useEffect(() => {
        if (initialLoad) {
            setTimeout(() => {
                setInitialLoad(false);
            }, 1500);
        }

        const handleScroll = () => {
            const scrolled = window.scrollY;
            document.querySelectorAll('.scroll-fade').forEach(el => {
                if (scrolled + window.innerHeight >= el.offsetTop + 100 && scrolled <= el.offsetTop + el.clientHeight) {
                    el.style.opacity = 1;
                } else {
                    el.style.opacity = 0;
                }
            });
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [initialLoad]);

    const fadeClass = 'scroll-fade';

    return (
        <div className="container">
            <h1 className="special-h1">Beautizio</h1>
            <div className="intro-section initial-fade">
                <div className={`intro-img-container`}>
                    <img src={aicosmetics} alt="Alchemy of beauty" className="intro-img"/>
                </div>
                <h2 className={`special-h2`}>AI Meets Your Beauty Routine</h2>
                <p className={`intro-description`}>
                    Beautizio combines the power of AI with skincare expertise. It analyzes your beauty products, offering personalized advice and compatibility scores. Whether it's a single item or a combination, Beautizio tailors your skincare routine to your unique needs, all while allowing you to save and revisit your customized plans. Experience the future of beauty, simplified and enhanced by technology.
                </p>
                <p className="privacy-link">
                    Read our <Link to="/terms-and-conditions#privacy">Privacy Policy</Link>.
                </p>
            </div>

            <div className="feature-sections">
                <div className={`feature-section ${fadeClass}`}>
                    {/*<div className="feature-content">*/}
                    {/*    <h3>Start with a Snapshot</h3>*/}
                    {/*    <p>Embark on your personalized beauty journey with just a snapshot. Simply snap or upload a photo of your product's ingredient label to begin.</p>*/}
                    {/*    <p>Whether analyzing a single product or multiple items, our advanced AI technology meticulously examines every detail. This initiates a tailored beauty experience, offering insights on what your products should or shouldn't be combined with.</p>*/}
                    {/*</div>*/}
                    {/*<img src={snapshot} alt="Scan labels" className="feature-img" />*/}
                    <div className={'centered-feature-content'}>
                        <h3>📸 Snap or Upload Photo</h3>
                        {/*<p>Upload a photo of your product's ingredient label.</p>*/}
                        <p>Embark on your personalized beauty journey with just a snapshot.
                            Simply snap or upload a photo of your product's ingredient label
                            to begin.</p>
                    </div>

                </div>
                <div className={`feature-section ${fadeClass}`}>
                    {/*<div className="feature-content">*/}
                    {/*    <h3>Synergy Score for Multiple Products</h3>*/}
                    {/*    <p>Discover the synergy between your beauty products with our AI-driven 'Score'. Designed for when you mix multiple items, it assesses their compatibility.</p>*/}
                    {/*    <p>The score is divided into four levels: 'Perfect Match' for ideal combinations, 'Compatible' for harmonious pairings, 'Should be Alternated' for products best used at different times, and 'Not Compatible' for potential adverse interactions.</p>*/}
                    {/*    <p>Use this score as your guide for a harmonious skincare routine.</p>*/}

                    {/*</div>*/}
                    {/*<img src={score} alt="Product Synergy Score" className="feature-img" />*/}
                    <div className={'centered-feature-content'}>
                        <h3>🔍 Discover Compatibility</h3>
                        {/*<p>Discover the synergy between two beauty products with AI-driven*/}
                        {/*    'Score'.</p>*/}
                        <p>Discover the synergy between your beauty products with our
                            AI-driven 'Score'. Designed for when you mix multiple items, it
                            assesses their compatibility.</p>
                    </div>
                </div>
                <div className={`feature-section ${fadeClass}`}>
                    {/*<div className="feature-content">*/}
                    {/*    <h3>AI-Driven Enhancement Insights</h3>*/}
                    {/*    <p>Our AI analyses single and multiple products, suggesting enhancements and missing ingredients for optimal performance. It boosts individual and multiple products effectiveness and evaluates product combinations for synergistic benefits.</p>*/}
                    {/*    <p>Enhance your beauty routine with tailored recommendations, ensuring a comprehensive and effective skincare regimen.</p>*/}
                    {/*</div>*/}
                    {/*<img src={enhancements} alt="Product enhancements" className="feature-img" />*/}
                    <div className={'centered-feature-content'}>
                        <h3>💡 Enhance Your Routine</h3>
                        {/*<p>AI analyses single and multiple products, suggesting enhancements.</p>*/}
                        <p>Our AI analyses single and multiple products, suggesting
                            enhancements and missing ingredients for optimal performance.</p>
                    </div>
                </div>
                <div className={`feature-section ${fadeClass}`}>
                    {/*<div className="feature-content">*/}
                    {/*    <h3>Contraindication Awareness</h3>*/}
                    {/*    <p>Our Compatibility Checks highlight contraindications to protect your skincare routine. For single products, it identifies ingredients to avoid, preventing negative interactions. For multiple products, the AI examines their compatibility, alerting you to potential conflicts.</p>*/}
                    {/*    <p>This careful analysis helps you avoid incompatible ingredient combinations, ensuring the efficacy and safety of your skincare regimen, whether using products separately or together.</p>*/}
                    {/*</div>*/}
                    {/*<img src={contraindications} alt="Contraindication check" className="feature-img" />*/}
                    <div className={'centered-feature-content'}>
                        <h3>⚠️ Check for Contraindications</h3>
                        {/*<p>Compatibility Checks highlights potential contraindications.</p>*/}
                        <p>Our Compatibility Checks highlight contraindications to protect
                            your skincare routine. For single products, it identifies
                            ingredients to avoid.</p>
                    </div>
                </div>
                <div className={`feature-section ${fadeClass}`}>
                    {/*<div className="feature-content">*/}
                    {/*    <h3>Your Customized Beauty Regimen</h3>*/}
                    {/*    <p>Discover a beauty routine crafted just for you, blending scientific precision with artistic creativity. Our algorithm tailors a personalized regimen based on your product choices, delivering unparalleled beauty innovation.</p>*/}
                    {/*</div>*/}
                    {/*<img src={routine} alt="Personalized beauty routine" className="feature-img" />*/}
                    <div className={'centered-feature-content'}>
                        <h3>🧴 Personalized Routine</h3>
                        {/*<p>Discover a beauty routine crafted just for you.</p>*/}
                        <p>Discover a beauty routine crafted just for you, blending
                            scientific precision with artistic creativity.</p>
                    </div>
                </div>
                <div className={`feature-section ${fadeClass}`}>
                    {/*<div className="feature-content">*/}
                    {/*    <h3>Personalize and Archive Your AI Insights</h3>*/}
                    {/*    <p>Our app empowers you to name, save, and review your AI-crafted beauty routines. Create a bespoke collection of skincare plans, featuring AI-recommended products and advice. Access your personalized insights in 'My Advices' to refine your beauty journey over time.</p>*/}
                    {/*</div>*/}
                    {/*<img src={save} alt="Personalized AI Advice" className="feature-img" />*/}
                    <div className={'centered-feature-content'}>
                        <h3>💾 Save Your Plans</h3>
                        {/*<p>Upload a photo of your product's ingredient label.</p>*/}
                        <p>Our app empowers you to name, save, and review your AI-crafted
                            beauty routines. Create a bespoke collection of skincare plans.</p>
                    </div>
                </div>

            </div>

            <div className="cta-section">
                <h2>Are you ready to revolutionize your beauty journey?</h2>
                <Link to="/upload-images">
                    <button>Unveil Your Perfect Routine</button>
                </Link>
            </div>
        </div>
    );
};

export default HomePage;
