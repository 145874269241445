import { requestInterceptor } from "./requestInterceptor";
const serverUrl = process.env.REACT_APP_SERVER_URL;

export const blogService = {
  baseUrl: serverUrl,

  async getBlogPosts(page, pageSize, searchTerm = '') {
    const request = requestInterceptor();
    try {
      const response = await request.get(`${this.baseUrl}/blogs`, {
        params: {
          page,
          page_size: pageSize,
          search: searchTerm, // Assuming your API supports a search parameter
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async getBlogPostById(id) {
    const request = requestInterceptor();
    try {
      const response = await request.get(`${this.baseUrl}/blogs/${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async getLatestPosts(blogs_number, current_blog_id) {
    const request = requestInterceptor();
    try {
      const response = await request.get(`${this.baseUrl}/latest-blogs`, {
        params: {
          blogs_number: blogs_number,
          current_blog_id: current_blog_id,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
};

