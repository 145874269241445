import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { adviceService } from '../../services/adviceService';
import { parseAdviceDetails, parseRoutineSteps, getScoreDetails } from '../../utils/adviceUtils';
import Errors from "../shared/Errors";
import {getErrorMessage} from "../../utils/commonMethods";

const ProductAdviceDetails = () => {

    const navigate = useNavigate();

    const location = useLocation();
    const originalAdvice = location.state.advice;

    const [advice, setAdvice] = useState({ ...originalAdvice });
    const [isEditing, setIsEditing] = useState(false);

    const [showErrors, setShowErrors] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const score = advice ? advice.compatibility_score : null;
    const { word: scoreWord, className: scoreColor} = getScoreDetails(score);


    const handleChange = (e, key) => {
        const { name, value } = e.target;
        if (key) { // Assuming `key` is passed for JSON fields like 'enhancement' and 'contraindications'
            const updatedAdvice = { ...advice };
            try {
                const jsonValue = JSON.parse(updatedAdvice[key]);

                if (value.length < 5) {
                    // Directly set the error message and show it without throwing an error
                    setErrorMessage(`${name} must be at least 5 characters long.`);
                    setShowErrors(true);
                    return; // Exit the function to prevent further processing
                }

                jsonValue[name] = value;
                updatedAdvice[key] = JSON.stringify(jsonValue);
            } catch (error) {
                const errorMessage = getErrorMessage(error);
                setErrorMessage(errorMessage);
                setShowErrors(true);
                return;
            }
            setAdvice(updatedAdvice);
        } else {
            // Direct string update for fields like 'routine'
            setAdvice({
                ...advice,
                [name]: value,
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await adviceService.updateAdvice(advice.id, advice);
            setIsEditing(false);
        } catch (error) {

            const errorMessage = getErrorMessage(error);

            setErrorMessage(errorMessage);
            setShowErrors(true);
        }
    };

    const handleDelete = async () => {
        if (window.confirm("Are you sure you want to delete this advice?")) {
            try {
                await adviceService.deleteAdvice(advice.id);
                navigate("/advices");
            } catch (error) {
                const errorMessage = getErrorMessage(error);
                setErrorMessage(errorMessage);
                setShowErrors(true);
            }
        }
    };


    const renderJsonFields = (jsonString, key) => {
        let jsonData;
        try {
            jsonData = JSON.parse(jsonString);
        } catch (error) {

            const errorMessage = getErrorMessage(error);

            setErrorMessage(errorMessage);
            setShowErrors(true);

            jsonData = {}; // Set to empty object as a fallback
        }

        return Object.entries(jsonData).map(([fieldKey, value], index) => (
            <div key={index}>
                <label>
                    {fieldKey}:
                    <textarea name={fieldKey} value={value} onChange={(e) => handleChange(e, key)} />
                </label>
            </div>
        ));
    };


    return (
        <main>
            <Errors
                show={showErrors}
                message={errorMessage}
                onClose={() => setShowErrors(false)}
            />
            <h1>{advice.name}</h1>
            {isEditing ? (
                <form onSubmit={handleSubmit}>
                    <label>
                        <h2>Name</h2>
                        <input
                            type="text"
                            name="name"
                            value={advice.name}
                            onChange={(e) => handleChange(e)}
                        />
                    </label>
                    <label>
                        <h2>Enhancement</h2>
                        {renderJsonFields(advice.enhancement, 'enhancement')}
                    </label>
                    <label>
                        <h2>Contraindications</h2>
                        {renderJsonFields(advice.contraindications, 'contraindications')}
                    </label>
                    <label>
                        <h2>Routine</h2>
                        <textarea name="routine" value={advice.routine} onChange={(e) => handleChange(e)} />
                    </label>
                    <button type="submit">Save Changes</button>
                    <button type="button" onClick={() => setIsEditing(false)}>Cancel</button>
                </form>
            ) : (
                <>
                    {score !== null && (
                        <div>
                            <h2>Compatibility Score</h2>
                            <div className={scoreColor}>
                                {scoreWord}
                            </div>
                        </div>
                    )}
                    <h2>Should Combine With</h2>
                    <p>{parseAdviceDetails(advice.enhancement)}</p>
                    <h2>Contraindications</h2>
                    <p>{parseAdviceDetails(advice.contraindications)}</p>
                    <h2>Routine</h2>
                    <p>{parseRoutineSteps(advice.routine)}</p>
                    <button onClick={() => setIsEditing(true)}>Edit Advice</button>
                    <button type="button" onClick={handleDelete}>Delete Advice</button>
                </>
            )}
            <Link to="/advices">
                <button>Back to Advices</button>
            </Link>
        </main>
    );
};

export default ProductAdviceDetails;

