import React, { useContext, useEffect, useState } from 'react';
import { authService } from "../../services/authService";
import AuthContext from "../../contexts/AuthContext";
import { Link, useNavigate } from 'react-router-dom';
import Errors from "../shared/Errors";
import { getErrorMessage } from "../../utils/commonMethods";
import FacebookLoginButton from "./FacebookLoginButton";
import GoogleLoginButton from "./GoogleLoginButton";

const Register = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState(''); // Step 1
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [showErrors, setShowErrors] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [genderRaw, setGenderRaw] = useState('');
    const [isFacebookApp, setIsFacebookApp] = useState(false); // New state variable

    const navigate = useNavigate();
    const { setIsAuthenticated, setIsAdmin } = useContext(AuthContext);

    useEffect(() => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        if (userAgent.includes("FBAN") || userAgent.includes("FBAV")) {
            setIsFacebookApp(true);
        }
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!termsAccepted) {
            setErrorMessage("You must accept the terms and conditions to proceed.");
            setShowErrors(true);
            return;
        }

        if (password !== passwordConfirmation) {
            setErrorMessage("Passwords do not match.");
            setShowErrors(true);
            return;
        }

        if (password.length < 6) {
            setErrorMessage("Password must be at least 6 characters long.");
            setShowErrors(true);
            return;
        }

        try {
            const gender = genderRaw ? parseInt(genderRaw, 10) : null;
            await authService.register(name, surname, email, password, gender, setIsAuthenticated, setIsAdmin);
            navigate('/');
        } catch (error) {
            const errorMessage = getErrorMessage(error);
            setErrorMessage(errorMessage);
            setShowErrors(true);
        }
    };

    return (
        <form onSubmit={handleSubmit} className="form box initial-fade">
            <Errors show={showErrors} message={errorMessage} onClose={() => setShowErrors(false)} />
            <label>Name</label>
            <input type="text" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} className="form-input" required />
            <label>Surname</label>
            <input type="text" placeholder="Surname" value={surname} onChange={(e) => setSurname(e.target.value)} className="form-input" required />
            <label>Email</label>
            <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} className="form-input" required />
            <label>Password</label>
            <input type="password" id="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} className="form-input" required />
            <label>Confirm Password</label> {/* Step 2 */}
            <input type="password" id="passwordConfirmation" placeholder="Confirm Password" value={passwordConfirmation} onChange={(e) => setPasswordConfirmation(e.target.value)} className="form-input" required />
            <label>Gender</label>
            <select value={genderRaw} onChange={(e) => setGenderRaw(e.target.value)} className="form-input">
                <option value="">Select Gender</option>
                <option value="1">Male</option>
                <option value="2">Female</option>
                <option value="3">Other</option>
            </select>
            <div className="terms-container">
                <input
                    type="checkbox"
                    id="terms"
                    checked={termsAccepted}
                    onChange={() => setTermsAccepted(!termsAccepted)}
                />
                <label htmlFor="terms"> I accept the <Link to="/terms-and-conditions">Terms and Conditions</Link></label>
            </div>
            <button type="submit" disabled={!termsAccepted}>Register</button>
            {!isFacebookApp && (
                <>
                    <FacebookLoginButton
                        setIsAuthenticated={setIsAuthenticated}
                        setIsAdmin={setIsAdmin}
                        onSuccess={() => console.log('Logged in via Facebook')}
                        navigateOnSuccess={true}
                        targetRoute="/upload-images"
                    />
                    <GoogleLoginButton
                        setIsAuthenticated={setIsAuthenticated}
                        onSuccess={() => console.log('Login successful!')}
                        navigateOnSuccess={true}
                        targetRoute="/upload-images"
                    />
                </>
            )}
            <div style={{ marginTop: '20px' }}>
                Already have an account? <Link to="/login">Log in</Link>
            </div>
        </form>
    );
};

export default Register;
