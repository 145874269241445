import CreateModel from "./CreateModel";
import GetAllModels from "./GetAllModels";
import AddIngredientToProductModel from "./AddIngredientToProductModel";

function Admin() {
    return (
        <main>
            <CreateModel />
            <GetAllModels />
            <AddIngredientToProductModel />
        </main>
    );
}

export default Admin;
