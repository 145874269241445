export const parseAdviceDetails = (adviceDetails) => {
    try {
        return Object.entries(JSON.parse(adviceDetails)).map(
            ([key, value], index) => (
                <div key={index}>
                    <strong>{key}:</strong> {value}
                </div>
            )
        );
    } catch (error) {
        console.error("Error parsing advice details:", error);
        return <div>Error displaying advice details.</div>;
    }
};

export const parseRoutineSteps = (routine) => {
    const steps = routine
        .split(/\d+\.\s+/)
        .filter((step) => step.trim().length > 0);

    return (
        <ol>
            {steps.map((step, index) => (
                <li key={index}>{step}</li>
            ))}
        </ol>
    );
};

const extractTextFromJSX = (jsxElement) => {
    if (!jsxElement) return '';
    if (typeof jsxElement === 'string') return jsxElement;

    if (Array.isArray(jsxElement)) {
        return jsxElement.map(extractTextFromJSX).join(' ');
    }

    if (typeof jsxElement === 'object' && jsxElement.props) {
        return extractTextFromJSX(jsxElement.props.children);
    }

    return '';
};

export const shortenText = (jsxElement, maxLength = 80) => {
    const text = extractTextFromJSX(jsxElement);
    if (text.length <= maxLength) return text;
    return text.substring(0, maxLength) + '...';
};

export const getScoreDetails = (score) => {
    const scoreMapping = {
        1: { word: "Perfect Match", className: "score-perfect" },
        2: { word: "Compatible", className: "score-compatible" },
        3: { word: "Should be Alternated", className: "score-alternated" },
        4: { word: "Not Compatible", className: "score-not-compatible" },
        0: { word: "No Score", className: "score-no-score" },
        null: { word: "Not Available", className: "score-not-available" }
    };
    return scoreMapping[score] || scoreMapping.null;
};
