import React, {useContext, useEffect, useState} from "react";
import InfiniteCarousel from "../carousel/InfiniteCarousel";
import {
  parseAdviceDetails,
  parseRoutineSteps,
  getScoreDetails,
} from "../../utils/adviceUtils";
import { adviceService } from "../../services/adviceService.js";
import {getErrorMessage} from "../../utils/commonMethods";
import Errors from "../shared/Errors";
import AuthContext from "../../contexts/AuthContext";
import {imageService} from "../../services/imagesService";

const AdviceModal = ({
  show,
  onClose,
  productAdvice,
  productAds,
                       onSaveSuccess
}) => {

  const [shownItemsToSend, setShownItemsToSend] = useState(new Set());

  const { isAuthenticated } = useContext(AuthContext);
  const [productName, setProductName] = useState("");

  const [showErrors, setShowErrors] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleSendShownItemsToBackend = async () => {
    try {
      await adviceService.sendItems(shownItemsToSend);
      console.log("Items sent successfully.");
      setShownItemsToSend(new Set());
    } catch (error) {
      console.error("Error sending items:", error);
    }
  };

  const handleCloseModal = () => {
    handleSendShownItemsToBackend(); // Send shown items to backend
    onClose(); // Close the modal
  };

  const handleSaveProduct = async () => {
    if (!isAuthenticated) {
      setErrorMessage("Authentication is required.");
      setShowErrors(true);
      return;
    }

    try {
      const responseData = await imageService.saveProduct(productAdvice, productName);

      onSaveSuccess();

      // Proceed with any follow-up actions after successful save
      onClose(); // Close the modal on successful save
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      setErrorMessage(errorMessage);
      setShowErrors(true);
    }
  };


  if (!show) {
    return null;
  }

  const score = productAdvice ? productAdvice.compatibility_score : null;
  const { word: scoreWord, className: scoreColor } = getScoreDetails(score);

  return (
    <div className="custom-modal">
      <Errors
          show={showErrors}
          message={errorMessage}
          onClose={() => setShowErrors(false)}
      />
      <div className="custom-modal-content">
        <h1>Product Advice</h1>
        <div className="modal-body">
          <form>
            <h2>Name</h2>
            <input type="text" value={productName} onChange={e => setProductName(e.target.value)} maxLength="1000" />
          </form>
          {score !== null && (
            <div>
              <h2>Compatibility Score</h2>
              <div className={scoreColor}>{scoreWord}</div>
            </div>
          )}
          {productAdvice && (
            <>
              <h2>Should Combine With</h2>
              <div>{parseAdviceDetails(productAdvice.enhancement)}</div>
              <h2>Contraindications</h2>
              <div>{parseAdviceDetails(productAdvice.contraindications)}</div>
              <h2>Routine</h2>
              <div>{parseRoutineSteps(productAdvice.routine)}</div>
            </>
          )}
          <InfiniteCarousel
            productAds={productAds}
            setShownItemsToSend={setShownItemsToSend}
          />
        </div>
        <div className="modal-footer">
          <button type="button" onClick={handleSaveProduct}>
            Save
          </button>
          <button type="button" onClick={handleCloseModal}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default AdviceModal;
