import React, {useContext, useState} from 'react';
import { NavLink } from 'react-router-dom';
import { authService } from "../../services/authService";
import AuthContext from "../../contexts/AuthContext";
import {LOGO} from "../../utils/constants";


const Header = () => {

  const { isAuthenticated, setIsAuthenticated, isAdmin, setIsAdmin } = useContext(AuthContext);

  const [isMenuOpen, setIsMenuOpen] = useState(false); // new state

  const handleLogout = () => {
    authService.logout();
    setIsAuthenticated(false);
    setIsAdmin(false);
  };

  return (
      <header>
        <nav>
          <div className="logo">
            <NavLink to="/">
              <img src={LOGO} alt="Your Logo" />
            </NavLink>
          </div>
          <button className="hamburger-button" onClick={() => setIsMenuOpen(!isMenuOpen)}>
            ☰
          </button>
          <ul className={`nav-list ${isMenuOpen ? 'open' : ''}`}>
            <li className="nav-item">
              <NavLink
                  to="/"
                  activeClassName="active"
                  className="nav-link"
              >
                Home
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                  to="/upload-images"
                  activeClassName="active"
                  className="nav-link"
              >
                Upload
              </NavLink>
            </li>
            {isAuthenticated && (
                <li className="nav-item">
                  <NavLink
                      to="/advices"
                      activeClassName="active"
                      className="nav-link"
                  >
                    Advices
                  </NavLink>
                </li>
            )}
            <li className="nav-item">
              <NavLink
                  to="/blogs"
                  activeClassName="active"
                  className="nav-link"
              >
                Blog
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                  to="/terms-and-conditions"
                  activeClassName="active"
                  className="nav-link"
              >
                Terms & Conditions
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                  to="/contacts"
                  activeClassName="active"
                  className="nav-link"
              >
                Contacts
              </NavLink>
            </li>
            {!isAuthenticated && (
                <React.Fragment>
                  <li className="nav-item">
                    <NavLink to="/register" className={({ isActive }) => (isActive ? "active" : "")}>
                      Register
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/login" className={({ isActive }) => (isActive ? "active" : "")}>
                      Login
                    </NavLink>
                  </li>
                </React.Fragment>
            )}
            {isAuthenticated && (
                <React.Fragment>
                  {isAdmin && (
                      <li className="nav-item">
                        <NavLink
                            to="/admin"
                            activeClassName="active"
                            className="nav-link"
                        >
                          Admin
                        </NavLink>
                      </li>
                  )}
                  <li className="nav-item">
                    <NavLink to="/profile"
                             activeClassName="active"
                             className="nav-link">
                      Profile
                    </NavLink>

                    <button onClick={handleLogout}>Logout</button>
                  </li>
                </React.Fragment>
            )}
          </ul>
        </nav>
      </header>
  );
};

export default Header;
