import {requestInterceptor} from "./requestInterceptor";

const serverUrl = process.env.REACT_APP_SERVER_URL;

export const adminService = {
    baseUrl: serverUrl,

    async create(selectedModel, formData) {
        try {
            const request = requestInterceptor();
            const response = await request.post(
                `${this.baseUrl}/${selectedModel}`,
                formData
            );

            return response.data;
        } catch (error) {
            console.error('Error uploading image:', error);
            throw error;
        }

    },

    async getAll(selectedModel) {
        try {
            const request = requestInterceptor();
            const response = await request.get(
                `${this.baseUrl}/${selectedModel}`
            );

            return response.data;
        } catch (error) {
            console.error('Error fetching data:', error);
            throw error;
        }
    },

    async update(selectedModel, id, formData) {
        try {
            const request = requestInterceptor();
            const response = await request.put(
                `${this.baseUrl}/${selectedModel}/${id}`,
                formData
            );

            return response.data;
        } catch (error) {
            console.error('Error updating model:', error);
            throw error;
        }
    },

    async delete(selectedModel, id) {
        try {
            const request = requestInterceptor();
            const response = await request.delete(
                `${this.baseUrl}/${selectedModel}/${id}`, // Assuming your API endpoint for deletion is structured like this
                {} // You might not need to send any body for a soft delete, depending on your API
            );

            return response.data;
        } catch (error) {
            console.error('Error deleting model:', error);
            throw error;
        }
    }

};

