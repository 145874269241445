import axios from "axios";
import { authService } from './authService';


export const requestInterceptor = (navigate) => {

    // Initialize history
    const instance = axios.create();

    // Add a request interceptor
    instance.interceptors.request.use((config) => {
        const token = localStorage.getItem("token");

        // If the data is not FormData, set 'Content-Type' as 'application/json'
        if (!(config.data instanceof FormData)) {
            config.headers['Content-Type'] = 'application/json';
        }

        // If the token exists, add it to the Authorization header
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }

        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    // Add a response interceptor
    instance.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            if (error.response && error.response.status === 401) {
                // If we get a 401 status, logout and redirect to login page
                authService.logout();
                navigate("/login"); // Navigate to login
            }

            return Promise.reject(error);
        }
    );

    return instance;
};

