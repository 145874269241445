import axios from "axios";
import { requestInterceptor } from "./requestInterceptor";

const serverUrl = process.env.REACT_APP_SERVER_URL;

const setAuthTokenAndUser = (token, user, setIsAuthenticated, setIsAdmin) => {
  localStorage.setItem("token", token);
  localStorage.setItem("user", JSON.stringify(user));

  setIsAuthenticated(true);

  if (user.role === "admin") {
    setIsAdmin(true);
    localStorage.setItem("isAdmin", "true");
  } else {
    setIsAdmin(false);
    localStorage.setItem("isAdmin", "false");
  }
};

export const authService = {
  baseUrl: serverUrl,

  async register(
    name,
    surname,
    email,
    password,
    gender,
    setIsAuthenticated,
    setIsAdmin
  ) {
    const response = await axios.post(
      `${this.baseUrl}/register`,
      {
        name,
        surname,
        email,
        password,
        gender,
      },
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );

    // Get the JWT token and user data from the response
    const { token, user } = response.data;

    setAuthTokenAndUser(token, user, setIsAuthenticated, setIsAdmin);
  },

  async login(email, password, setIsAuthenticated, setIsAdmin) {
    const response = await axios.post(`${this.baseUrl}/login`, {
      email,
      password,
    });

    // Get the JWT token and user data from the response
    const { token, user } = response.data;

    setAuthTokenAndUser(token, user, setIsAuthenticated, setIsAdmin);

    return { token, user };
  },

  async confirmProfile(token) {
    try {
      const response = await axios.get(`${this.baseUrl}/confirm-profile`, {
        params: { token } // Pass token as query parameter
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("isAdmin");

    window.location.href = "/login";
  },

  getToken() {
    return localStorage.getItem("token");
  },

  getAdmin() {
    return localStorage.getItem("isAdmin");
  },

  async getUserInfo() {
    try {
      const request = requestInterceptor(); // Assuming this sets up headers

      // Replace with your API endpoint for getting user info
      const response = await request.get(`${this.baseUrl}/profile`);

      // Update user info in local storage or state as needed
      const userInfo = response.data;
      localStorage.setItem("user", JSON.stringify(userInfo));

      return userInfo;
    } catch (error) {
      throw error;
    }
  },

  async updateProfile(formData) {
    try {
      const request = requestInterceptor();

      const response = await request.put(`${this.baseUrl}/profile`, formData);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async changePassword(formData) {
    try {
      const request = requestInterceptor();

      const response = await request.put(
        `${this.baseUrl}/change-password`,
        formData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async requestPasswordReset(email) {
    try {
      const formData = new FormData();
      formData.append('email', email);

      const request = requestInterceptor();
      const response = await request.post(`${this.baseUrl}/request-password-reset`, formData);

      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async resetPassword(token, newPassword) {
    try {
      const formData = new FormData();
      formData.append('token', token);
      formData.append('new_password', newPassword);

      const request = requestInterceptor();
      const response = await request.post(`${this.baseUrl}/reset-password`, formData);

      return response.data;
    } catch (error) {
      throw error;
    }
  }

};
