import React, { useContext, useEffect, useState } from 'react';
import { authService } from "../../services/authService";
import AuthContext from "../../contexts/AuthContext";
import { Link, useNavigate } from 'react-router-dom';
import { getErrorMessage } from "../../utils/commonMethods";
import FacebookLoginButton from "./FacebookLoginButton";
import GoogleLoginButton from "./GoogleLoginButton";

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loginError, setLoginError] = useState('');
    const [isFacebookApp, setIsFacebookApp] = useState(false); // New state variable

    const { setIsAuthenticated, setIsAdmin } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        if (userAgent.includes("FBAN") || userAgent.includes("FBAV")) {
            setIsFacebookApp(true);
        }
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            await authService.login(email, password, setIsAuthenticated, setIsAdmin);
            navigate('/');
        } catch (error) {
            const errorMessage = getErrorMessage(error);
            setLoginError(errorMessage);
        }
    };

    return (
        <form onSubmit={handleSubmit} className="form box initial-fade">
            <label>Email</label>
            <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} className="form-input" required />
            <label>Password</label>
            <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} className="form-input" required />
            {loginError && <p className="error" style={{ color: "red" }}>{loginError}</p>}
            <button type="submit">Login</button>
            {!isFacebookApp && (
                <>
                    <FacebookLoginButton
                        setIsAuthenticated={setIsAuthenticated}
                        setIsAdmin={setIsAdmin}
                        onSuccess={() => console.log('Logged in via Facebook')}
                        navigateOnSuccess={true}
                        targetRoute="/upload-images"
                    />
                    <GoogleLoginButton
                        setIsAuthenticated={setIsAuthenticated}
                        onSuccess={() => console.log('Login successful!')}
                        navigateOnSuccess={true}
                        targetRoute="/upload-images"
                    />
                </>
            )}
            <div style={{ marginTop: '20px' }}>
                Don't have an account? <Link to="/register">Register</Link>
            </div>
            <div style={{ marginTop: '20px' }}>
                Forgot your password? <Link to="/password-reset-request">Reset</Link>
            </div>
        </form>
    );
};

export default Login;
