import axios from 'axios';

const serverUrl = process.env.REACT_APP_SERVER_URL;

const googleService = {
    baseUrl: serverUrl,

    async authenticateWithToken(idToken, setIsAuthenticated) {
        try {
            const response = await axios.post(`${this.baseUrl}/google-login`, {
                token: idToken
            }, {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                }
            });

            const { token, user } = response.data;
            localStorage.setItem("token", token);
            localStorage.setItem("user", JSON.stringify(user));
            setIsAuthenticated(true);
        } catch (error) {
            console.error("Error sending Google ID token to backend:", error);
            throw error; // Re-throw the error so it can be caught in the component
        }
    }
};

export default googleService;
