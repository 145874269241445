import React, { useState } from "react";
import { Form, Button, Dropdown } from "react-bootstrap";
import { adminService } from "../../services/adminService";
import { Editor } from "@tinymce/tinymce-react";
import SuccessMessage from "../shared/SuccessMessage";
import Errors from "../shared/Errors";

const CreateModel = () => {
  const [selectedModel, setSelectedModel] = useState(null);
  const [formData, setFormData] = useState({});
  const [editorContent, setEditorContent] = useState("");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const [showErrors, setShowErrors] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [companies, setCompanies] = useState([]);
  const [brands, setBrands] = useState([]);
  const [types, setTypes] = useState([]);

  const modelOptions = [
    "BlogPost",
    "Company",
    "Brand",
    "Product",
    "Type",
    "Ingredient",
  ];

  const modelFields = {
    BlogPost: {
      title: "text",
      content: "text",
      meta_description: "text",
      meta_keywords: "text",
      short_content: "text",
      image_or_video_url: "text",
      type: "number",
      is_pinned: "checkbox",
    },
    Company: {
      name: "text",
      vat: "text",
      address: "text",
      owner: "text",
    },
    Brand: {
      name: "text",
      company_id: "number",
    },
    Product: {
      name: "text",
      description: "text",
      url_img: "text",
      url: "text",
      brand_id: "number",
      type_id: "number",
    },
    Type: {
      name: "text",
      description: "text",
    },
    Ingredient: {
      name: "text",
      description: "text",
      activeConstituent: "checkbox",
    },
  };

  const handleInputChange = (e, field) => {
    if (field !== "content") {
      if (e.target.type === "checkbox") {
        setFormData({ ...formData, [field]: e.target.checked });
      } else {
        setFormData({ ...formData, [field]: e.target.value });
      }
    }
  };

  const handleSubmit = () => {
    let isEmptyFieldPresent = false;
    const requiredFields = modelFields[selectedModel];

    // Check for empty fields in formData, ensuring .trim() is only called on strings
    Object.keys(requiredFields).forEach((field) => {
      if (field !== 'content') { // Exclude 'content' field from this check
        const value = formData[field];
        if (typeof value === "string" && value.trim() === "") {
          isEmptyFieldPresent = true;
        } else if (value === null || value === undefined || value === "") { // Check for non-string empty values
          isEmptyFieldPresent = true;
        }
      }
    });

    // Special handling for 'content' of BlogPost
    if (selectedModel === "BlogPost") {
      if (!editorContent || editorContent.trim() === "") {
        isEmptyFieldPresent = true;
      }
    }

    if (isEmptyFieldPresent) {
      setErrorMessage("Please fill in all fields.");
      setShowErrors(true);
      return; // Prevent submission if any field is empty
    }

    let submitData = { ...formData };

    // Convert numerical fields to numbers and include 'content' for BlogPost
    Object.entries(modelFields[selectedModel]).forEach(([field, fieldType]) => {
      if (fieldType === "number" && submitData[field]) {
        submitData[field] = parseInt(submitData[field], 10);
      }
    });

    if (selectedModel === "BlogPost") {
      submitData.content = editorContent;
    }

    // Proceed with submission
    adminService.create(selectedModel, submitData)
        .then(() => {
          setShowSuccessMessage(true);
          setFormData({});
          if (selectedModel === "BlogPost") {
            setEditorContent(""); // Clear editor content for BlogPost
          }
        })
        .catch((error) => {
          setErrorMessage(error.message || "Error creating model");
          setShowErrors(true);
        });
  };


  const handleModelSelect = (selected) => {
    setSelectedModel(selected);

    // Initialize form data with default values for checkboxes
    const initialFormData = {};
    if (modelFields[selected]) {
      Object.entries(modelFields[selected]).forEach(([key, value]) => {
        if (value === 'checkbox') {
          initialFormData[key] = false; // Default unchecked value
        }
      });
    }

    setFormData(initialFormData); // Reset the form data with checkbox defaults

    if (selected === "Brand") {
      adminService
        .getAll("Company")
        .then((response) => {
          setCompanies(response || []);
        })
        .catch((error) => console.error("Error fetching companies:", error));
    }

    if (selected === "Product") {
      adminService
        .getAll("Brand")
        .then((response) => {
          setBrands(response || []);
        })
        .catch((error) => console.error("Error fetching brands:", error));

      adminService
        .getAll("Type") // Fetch types when Product is selected
        .then((response) => {
          setTypes(response || []);
        })
        .catch((error) => console.error("Error fetching types:", error));
    }
  };

  return (
    <div>
      <h2>Create New Model</h2>
      <Errors
        show={showErrors}
        message={errorMessage}
        onClose={() => setShowErrors(false)}
      />
      {showSuccessMessage && (
        <SuccessMessage
          message="Model created successfully!"
          duration={5000}
          onDismiss={() => setShowSuccessMessage(false)}
        />
      )}

      <Dropdown onSelect={handleModelSelect}>
        <Dropdown.Toggle variant="success" id="dropdown-basic">
          Select Model
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {modelOptions.map((model, idx) => (
            <Dropdown.Item key={idx} eventKey={model}>
              {model}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>

      {selectedModel && (
        <Form className="form box">
          {Object.entries(modelFields[selectedModel]).map(
            ([field, fieldType], index) => {
              if (field === "company_id" && selectedModel === "Brand") {
                return (
                  <Form.Group key={index}>
                    <Form.Label>Company</Form.Label>
                    <Form.Control
                      as="select"
                      value={formData[field] || ""}
                      onChange={(e) => handleInputChange(e, field)}
                    >
                      <option value="">Select a company</option>
                      {companies.map((company) => (
                        <option key={company.id} value={company.id}>
                          {company.name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                );
              } else if (selectedModel === "Product") {
                if (field === "brand_id") {
                  return (
                    <Form.Group key={index}>
                      <Form.Label>Brand</Form.Label>
                      <Form.Control
                        as="select"
                        value={formData[field] || ""}
                        onChange={(e) => handleInputChange(e, field)}
                      >
                        <option value="">Select a brand</option>
                        {brands.map((brand) => (
                          <option key={brand.id} value={brand.id}>
                            {brand.name}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  );
                }
                if (field === "type_id") {
                  return (
                    <Form.Group key={index}>
                      <Form.Label>Type</Form.Label>
                      <Form.Control
                        as="select"
                        value={formData[field] || ""}
                        onChange={(e) => handleInputChange(e, field)}
                      >
                        <option value="">Select a type</option>
                        {types.map((type) => (
                          <option key={type.id} value={type.id}>
                            {type.name}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  );
                }
              }
              if (selectedModel === "BlogPost" && field === "content") {
                return (
                  <Form.Group key={index}>
                    <Form.Label>{field}</Form.Label>
                    <Editor
                      apiKey="gfmixgyb8k5ooka3vem9bf03o2dj12erzr3yguhsrb60oizl"
                      value={editorContent}
                      onEditorChange={setEditorContent}
                      init={{
                        height: 300,
                        menubar: false,
                      }}
                    />
                  </Form.Group>
                );
              } else {
                return (
                  <Form.Group key={index}>
                    <Form.Label>{field}</Form.Label>
                    {fieldType === "checkbox" ? (
                      <Form.Check
                        type="checkbox"
                        checked={formData[field] || false}
                        onChange={(e) => handleInputChange(e, field)}
                      />
                    ) : (
                      <Form.Control
                        type={fieldType}
                        className="form-input"
                        value={formData[field] || ""}
                        placeholder={`Enter ${field}`}
                        onChange={(e) => handleInputChange(e, field)}
                      />
                    )}
                  </Form.Group>
                );
              }
            }
          )}
          <Button variant="primary" onClick={handleSubmit}>
            Create
          </Button>
        </Form>
      )}
    </div>
  );
};

export default CreateModel;
