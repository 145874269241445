import React, { useState, useEffect } from "react";
import { Dropdown, Button, Row, Col } from "react-bootstrap";
import { adminService } from "../../services/adminService";
import Errors from "../shared/Errors";
import {getErrorMessage} from "../../utils/commonMethods";

const AddIngredientToProductModel = () => {
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [selectedIngredient, setSelectedIngredient] = useState(null);
    const [productData, setProductData] = useState([]);
    const [ingredientData, setIngredientData] = useState([]);

    const [errorMessage, setErrorMessage] = useState('');
    const [showErrors, setShowErrors] = useState(false);

    const [successMessage, setSuccessMessage] = useState('');

    const selectedProductName = productData.find(p => p.id === selectedProduct)?.name;
    const selectedIngredientName = ingredientData.find(i => i.id === selectedIngredient)?.name;


    useEffect(() => {
        // Fetch product data
        adminService
            .getAll("Product")
            .then((data) => {
                setProductData(data);
            })
            .catch((error) => {
                const errorMessage = getErrorMessage(error);
                console.error(errorMessage);
            });

        // Fetch ingredient data
        adminService
            .getAll("Ingredient")
            .then((data) => {
                setIngredientData(data);
            })
            .catch((error) => {
                const errorMessage = getErrorMessage(error);
                console.error(errorMessage);
            });
    }, []);


    const handleAddIngredientToProduct = () => {
        if (selectedProduct && selectedIngredient) {
            adminService
                .create("IngredientProductAssociation", {
                    product_id: selectedProduct,
                    ingredient_id: selectedIngredient,
                })
                .then((result) => {
                    setSuccessMessage("Ingredient added to product successfully.");
                    setErrorMessage(''); // Clear any previous error
                })
                .catch((error) => {
                    const errorMessage = getErrorMessage(error);
                    setErrorMessage(errorMessage);
                    // setErrorMessage("Error adding ingredient to product.");
                    setSuccessMessage(''); // Clear any previous success message
                    setShowErrors(true);
                });
        } else {
            setErrorMessage("Please select both product and ingredient.");
        }
    };


    return (
        <div>
            <h2>Add Ingredient to Product</h2>
            <Errors show={showErrors} message={errorMessage} onClose={() => setShowErrors(false)}/>
            {successMessage && <div className="success-message">{successMessage}</div>}
            <Row>
                <Col>
                    <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-product">
                            {selectedProductName || "Select Product"}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {productData.map((product) => (
                                <Dropdown.Item
                                    key={product.id}
                                    onClick={() => setSelectedProduct(product.id)}
                                    className={product.id === selectedProduct ? 'selected-item' : ''}
                                >
                                    {product.name}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
                <Col>
                    <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-ingredient">
                            {selectedIngredientName || "Select Ingredient"}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {ingredientData.map((ingredient) => (
                                <Dropdown.Item
                                    key={ingredient.id}
                                    onClick={() => setSelectedIngredient(ingredient.id)}
                                    className={ingredient.id === selectedIngredient ? 'selected-item' : ''}
                                >
                                    {ingredient.name}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
            </Row>

            <Button variant="primary" onClick={handleAddIngredientToProduct}>
                Add Ingredient to Product
            </Button>
        </div>
    );
};

export default AddIngredientToProductModel;
