import React from 'react';

const Errors = ({ show, message, onClose }) => {
    if (!show) return null;

    return (
        <div className="errors-overlay">
            <div className="errors-content">
                <h2>Error</h2>
                <p>{message}</p>
                <button onClick={onClose}>Close</button>
            </div>
        </div>
    );
};

export default Errors;
