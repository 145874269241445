// Inside the InfiniteCarousel component
import React, { useState, useEffect } from "react";

const InfiniteCarousel = ({ productAds, setShownItemsToSend }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [slides, setSlides] = useState([]);

  useEffect(() => {
    // Group products into slides (4 products per slide)
    const groupProductsIntoSlides = (products, productsPerSlide) => {
      return products.reduce((acc, curr, index) => {
        const slideIndex = Math.floor(index / productsPerSlide);
        acc[slideIndex] = acc[slideIndex] || [];
        acc[slideIndex].push(curr);
        return acc;
      }, []);
    };

    setSlides(groupProductsIntoSlides(productAds, 4));
  }, [productAds]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const nextIndex = (currentIndex + 1) % slides.length;
      setCurrentIndex(nextIndex);
      updateShownItems(nextIndex);
    }, 5000); // Change interval as needed

    return () => clearInterval(intervalId); // Clear interval on unmount
  }, [slides.length, currentIndex]);

  const updateShownItems = (index) => {
    if (slides.length > 0 && slides[0]) { // Ensure slides and the first slide are defined
      setShownItemsToSend((prevItems) => {
        const updatedItems = new Set([...prevItems]);
        // Include products from the first slide
        slides[0].forEach((product) => updatedItems.add(product.impression_id));
        // Check if the current slide exists before attempting to access it
        if (slides[index]) {
          slides[index].forEach((product) =>
              updatedItems.add(product.impression_id)
          );
        }
        console.log("Shown Items:", updatedItems);
        return updatedItems;
      });
    }
  };

  const handleCarouselClick = (direction) => {
    const newIndex =
      direction === "prev"
        ? currentIndex === 0
          ? slides.length - 1
          : currentIndex - 1
        : (currentIndex + 1) % slides.length;

    setCurrentIndex(newIndex);
    updateShownItems(newIndex);
  };

  return (
    <div className="carousel-container">
      <div
        className="carousel-arrow carousel-arrow-left"
        onClick={() => handleCarouselClick("prev")}
      >
        &lt;
      </div>
      <div
        className="carousel-arrow carousel-arrow-right"
        onClick={() => handleCarouselClick("next")}
      >
        &gt;
      </div>
      <div
        className="carousel-inner"
        style={{ transform: `translateX(-${currentIndex * 100}%)` }}
      >
        {slides.map((slide, index) => (
          <div key={index} className="carousel-slide">
            {slide.map((product, pIndex) => (
              <div key={pIndex} className="carousel-item">
                <h3>{product.name}</h3>
                <a href={product.url} target="_blank" rel="noopener noreferrer">
                  <img src={product.url_img} alt={`Product ${pIndex}`} />
                </a>
                <p>{product.description}</p>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default InfiniteCarousel;
