import React, { useState, useEffect } from "react";
import { Dropdown, Button } from "react-bootstrap";
import { adminService } from "../../services/adminService";
import EditModel from "./EditModel";
import {getErrorMessage} from "../../utils/commonMethods";

const GetAllModels = () => {
  const [selectedModel, setSelectedModel] = useState(null);
  const [data, setData] = useState([]);
  const [itemToEdit, setItemToEdit] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const [updateTrigger, setUpdateTrigger] = useState(0);

  const modelOptions = [
    "BlogPost",
    "Company",
    "Brand",
    "Product",
    "Type",
    "Ingredient",
  ];


  const handleSearch = () => {
    if (searchTerm.trim() === "") {
      setFilteredData(data);
    } else {
      setFilteredData(
          data.filter((item) =>
              Object.values(item).some(
                  (value) => typeof value === "string" && value.toLowerCase().includes(searchTerm.toLowerCase())
              )
          )
      );
    }
  };

  useEffect(() => {
    if (selectedModel) {
      adminService
          .getAll(selectedModel)
          .then((data) => {
            setData(data);
            setFilteredData(data);
          })
          .catch((error) => {
            const errorMessage = getErrorMessage(error);
            console.error(errorMessage);
          });
    }
  }, [selectedModel, updateTrigger]);

  useEffect(() => {
    if (searchTerm.trim() === "") {
      setFilteredData(data);
    } else {
      const lowerCaseSearchTerm = searchTerm.toLowerCase();
      setFilteredData(
          data.filter((item) =>
              Object.values(item).some(
                  (value) =>
                      typeof value === "string" &&
                      value.toLowerCase().includes(lowerCaseSearchTerm)
              )
          )
      );
    }
  }, [searchTerm, data]);

  const handleModelChange = (newModel) => {
    setSelectedModel(newModel);
    setSearchTerm(""); // Clear the search term whenever a new model is selected
    setFilteredData([]); // Optionally clear filteredData as well
  };

  const handleEdit = (item) => {
    setItemToEdit(item);
  };

  const handleEditSuccess = () => {
    setItemToEdit(null);
    setUpdateTrigger((prev) => prev + 1); // Increment to trigger re-fetch
    setSearchTerm("");
  };

  const handleDelete = (id) => {
    if (window.confirm('Are you sure you want to delete this item?')) { // Optional: Add a confirmation before deletion
      adminService.delete(selectedModel, id)
          .then(() => {
            setUpdateTrigger((prev) => prev + 1); // Trigger re-fetch of data
          })
          .catch((error) => {
            const errorMessage = getErrorMessage(error);
            console.error(errorMessage);
          });
    }
  };

  return (
    <div>
      <h2>View All Models</h2>
      <Dropdown onSelect={handleModelChange}>
        <Dropdown.Toggle variant="success" id="dropdown-basic">
          Select Model
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {modelOptions.map((model, idx) => (
            <Dropdown.Item key={idx} eventKey={model}>
              {model}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>

      {selectedModel && (
        <div>
          <h3>All {selectedModel}s</h3>
          <input
            type="text"
            placeholder="Search by Name"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <Button variant="primary" onClick={handleSearch}>
            Search
          </Button>
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  {Object.keys(data[0] || {}).map((key, idx) => (
                    <th key={idx}>{key}</th>
                  ))}
                  <th>Edit</th>
                  <th>Delete</th>
                </tr>
              </thead>
              {filteredData.length === 0 ? (
                <tbody>
                  <tr>
                    <td colSpan={Object.keys(data[0] || {}).length + 1}>
                      No results found.
                    </td>
                  </tr>
                </tbody>
              ) : (
                <tbody>
                  {filteredData.map((item, index) => (
                      <tr key={index}>
                        {Object.values(item).map((value, idx) => (
                            <td key={idx}>{value}</td>
                        ))}
                        <td>
                          <Button variant="warning" onClick={() => handleEdit(item)}>
                            Edit
                          </Button>
                        </td>
                        <td>
                          <Button variant="danger" onClick={() => handleDelete(item.id)}>
                            Delete
                          </Button>
                        </td>
                      </tr>
                  ))}

                </tbody>
              )}
              </table>
          </div>
        </div>
      )}
      {itemToEdit && (
        <EditModel
          selectedModel={selectedModel}
          itemToEdit={itemToEdit}
          onEditSuccess={handleEditSuccess}
        />
      )}
    </div>
  );
};

export default GetAllModels;
