import React, { useEffect, useContext } from 'react';
import AuthContext from "../../contexts/AuthContext";
import facebookService from "../../services/facebookService";  // Import the Facebook authentication service
import {Link, useNavigate} from 'react-router-dom';

const FacebookLoginButton = ({ onSuccess, navigateOnSuccess = false, targetRoute = '/' }) => {
    const { setIsAuthenticated, setIsAdmin} = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        window.fbAsyncInit = function() {
            window.FB.init({
                appId      : process.env.REACT_APP_FACEBOOK_APP_ID,
                cookie     : true,
                xfbml      : true,
                version    : 'v19.0'
            });

            window.FB.getLoginStatus(function(response) {
                statusChangeCallback(response);
            });
        };

        (function(d, s, id){
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s); js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
    }, []);

    const statusChangeCallback = (response) => {
        console.log(response);
        if (response.status === 'connected') {
            console.log('Successfully logged in with Facebook API:', response);

            authenticateUser(response.authResponse.accessToken)
        } else {
            console.log('User not authenticated');
        }
    };

    const authenticateUser = async (accessToken) => {
        try {
            await facebookService.authenticateWithToken(accessToken, setIsAuthenticated);

            if (onSuccess) {
                onSuccess();  // Execute the callback if provided
            }

            if (navigateOnSuccess) {
                navigate(targetRoute);
            }
        } catch (error) {
            console.error("Failed to authenticate with Facebook token:", error);
        }
    };

    const handleFBLogin = () => {
        window.FB.login(statusChangeCallback, {scope: 'public_profile,email'});
    };

    return (
        <div>
            <button class="fb connect" onClick={handleFBLogin}>
                Sign in with Facebook
            </button>
        </div>
    );
};

export default FacebookLoginButton;

