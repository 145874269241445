import React from 'react';

const AuthContext = React.createContext({
    isAuthenticated: false,
    isAdmin: false, // Add this
    setIsAuthenticated: () => {},
    setIsAdmin: () => {}, // And this
});

export default AuthContext;



