import React, { useState, useEffect } from 'react';

const SuccessMessage = ({ message, duration = 5000, onDismiss }) => {
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsVisible(false);
            if (onDismiss) onDismiss();
        }, duration);
        return () => clearTimeout(timer);
    }, [duration, onDismiss]);

    const handleClick = () => {
        setIsVisible(false);
        if (onDismiss) onDismiss();
    };

    return (
        isVisible && (
            <div className="success-message" onClick={handleClick}>
                <span className="message-icon">✔</span>
                <p className="message-text">{message}</p>
            </div>
        )
    );
};
export default SuccessMessage;
