import React, { useState, useEffect } from "react";
import { authService } from "../../services/authService";
import {getErrorMessage} from "../../utils/commonMethods";

const Profile = () => {
  const [activeTab, setActiveTab] = useState("details");
  const [userInfo, setUserInfo] = useState({
    email: "",
    name: "",
    surname: "",
    gender: "",
    password: "",
    new_password: "",
  });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const genderMap = {
    '1': 'Male',
    '2': 'Female',
    '3': 'Other',
  };

  useEffect(() => {
    authService.getUserInfo().then(data => {
      setUserInfo(prevState => ({
        ...prevState,
        ...data,
        // gender: Object.keys(genderMap).find(key => genderMap[key] === data.gender) || ''
        gender: genderMap[data.gender] || ''
      }));
    }).catch(err => setError("Failed to fetch user information."));
  }, []);

  const handleChange = e => {
    const { name, value } = e.target;
    setUserInfo(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setError("");
    setSuccess("");

    if (activeTab === "details") {
      try {

        const genderKey = Object.keys(genderMap).find(key => genderMap[key] === userInfo.gender);
        const genderId = parseInt(genderKey, 10);

        await authService.updateProfile({
          email: userInfo.email,
          name: userInfo.name,
          surname: userInfo.surname,
          gender: genderId
        });
        setSuccess("Profile updated successfully.");
      } catch (error) {
        const errorMessage = getErrorMessage(error);
        setError(errorMessage);
      }
    } else if (activeTab === "password") {

      if (userInfo.new_password.length < 6) {
        setError("New password must be at least 6 characters long.");
        return; // Prevents the form from submitting
      }

      try {
        await authService.changePassword({
          password: userInfo.password,
          new_password: userInfo.new_password
        });
        setSuccess("Password changed successfully.");
        setUserInfo(prevState => ({
          ...prevState,
          password: "",
          new_password: ""
        }));
      } catch (error) {
        const errorMessage = getErrorMessage(error);
        setError(errorMessage);
      }
    }
  };

  return userInfo ? (
      <div className="form box initial-fade">
        <div className="tabs">
          <button onClick={() => setActiveTab("details")}>Details</button>
          <button onClick={() => setActiveTab("password")}>
            Change Password
          </button>
        </div>

        {error && <p style={{ color: "red" }}>{error}</p>}
        {success && <p style={{ color: "green" }}>{success}</p>}

        {activeTab === "details" && (
            <form onSubmit={handleSubmit}>
              <label>Email</label>
              <input
                  type="email"
                  name="email"
                  className="form-input"
                  placeholder="Enter email"
                  value={userInfo.email}
                  onChange={handleChange}
                  required
              />

              <label>Name</label>
              <input
                  type="text"
                  name="name"
                  className="form-input"
                  placeholder="Name"
                  value={userInfo.name}
                  onChange={handleChange}
                  required
              />

              <label>Surname</label>
              <input
                  type="text"
                  name="surname"
                  className="form-input"
                  placeholder="Surname"
                  value={userInfo.surname}
                  onChange={handleChange}
                  required
              />
              <label>Gender</label>
              <select
                  name="gender"
                  className="form-input"
                  value={userInfo.gender} // Use the string representation for display
                  onChange={handleChange}
                  required
              >
                {Object.entries(genderMap).map(([key, value]) => (
                    <option key={key} value={value}>{value}</option>
                ))}
              </select>

              <button type="submit">Update Profile</button>
            </form>
        )}

        {activeTab === "password" && (
            <form onSubmit={handleSubmit}>
              <label>Current Password</label>
              <input
                  type="password"
                  name="password"
                  className="form-input"
                  placeholder="Current Password"
                  value={userInfo.password}
                  onChange={handleChange}
                  required
              />

              <label>New Password</label>
              <input
                  type="password"
                  name="new_password"
                  className="form-input"
                  placeholder="New Password"
                  value={userInfo.new_password}
                  onChange={handleChange}
                  required
              />

              <button type="submit">Change Password</button>
            </form>
        )}
      </div>
  ) : (
      <p>Loading...</p>
  );

};

export default Profile;

