// import React from 'react';
// import { Modal } from 'react-bootstrap';
// import { ThreeDots } from 'react-loader-spinner';
//
// const LoadingModal = ({ show }) => {
//     return (
//         <Modal show={show} centered size="sm" aria-labelledby="contained-modal-title-vcenter">
//             <Modal.Body className="text-center" style={{ padding: '20px' }}>
//                 <ThreeDots color="#8a76ca" height={50} width={50} />
//             </Modal.Body>
//         </Modal>
//     );
// };
//
// export default LoadingModal;

import React from 'react';
import { Modal } from 'react-bootstrap';
import { ThreeDots } from 'react-loader-spinner';

const LoadingModal = ({ show }) => {
    return (
        <Modal show={show} centered size="sm" aria-labelledby="contained-modal-title-vcenter">
            <Modal.Body className="loading-modal-body">
                <ThreeDots color="#8a76ca" height={50} width={50} />
            </Modal.Body>
        </Modal>
    );
};

export default LoadingModal;

