import React, { useState } from 'react';
import { authService } from "../../services/authService";
import {getErrorMessage} from "../../utils/commonMethods";  // assuming authService has the logic to call the backend

const PasswordResetRequest = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await authService.requestPasswordReset(email);
            setEmail('');
            setMessage("If the email is registered, a password reset link has been sent.");
        } catch (error) {
            const errorMessage = getErrorMessage(error);
            setMessage(errorMessage);
        }
    };

    return (
        <form onSubmit={handleSubmit} className="form box initial-fade">
            <label>Email</label>
            <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} className="form-input" required/>
            <button type="submit">Request Password Reset</button>
            {message && <p>{message}</p>}
        </form>
    );
};

export default PasswordResetRequest;
