import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { authService } from '../../services/authService';
import Errors from "../shared/Errors";
import {getErrorMessage} from "../../utils/commonMethods"; // Adjust the path

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function ProfileConfirmation() {
    const query = useQuery();
    const [message, setMessage] = useState('');

    const [showErrors, setShowErrors] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const confirmProfile = async () => {
        const token = query.get('token');
        if (token) {
            try {
                const response = await authService.confirmProfile(token);
                setMessage(response.message);
            } catch (error) {
                const errorMessage = getErrorMessage(error);
                setErrorMessage(errorMessage);
                setShowErrors(true);
            }
        } else {
            setErrorMessage("No token provided.");
            setShowErrors(true);
        }
    };

    return (
        <div>
            <Errors show={showErrors} message={errorMessage} onClose={() => setShowErrors(false)}/>
            <button onClick={confirmProfile}>Confirm Profile</button>
            {message && <p>{message}</p>}
        </div>
    );
}

export default ProfileConfirmation;
