import React, { useState, useEffect } from 'react';
import { adviceService } from '../../services/adviceService';
import { Link, useNavigate } from 'react-router-dom';
import { parseAdviceDetails, parseRoutineSteps, shortenText } from "../../utils/adviceUtils";
import LoadingModal from "../shared/Loading";
import { getErrorMessage } from "../../utils/commonMethods";
import Errors from "../shared/Errors";

const ProductAdvices = () => {
    const [productAdvices, setProductAdvices] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showErrors, setShowErrors] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const navigate = useNavigate();

    // Encapsulate fetching logic into a function
    const fetchAdvices = async () => {
        try {
            setIsLoading(true);
            const advices = await adviceService.getAdvices();
            if (advices) {
                setProductAdvices(advices);
            }
        } catch (error) {
            const errorMessage = getErrorMessage(error);
            setErrorMessage(errorMessage);
            setShowErrors(true);
        } finally {
            setIsLoading(false);
        }
    };

    // Call fetchAdvices on component mount
    useEffect(() => {
        fetchAdvices();
    }, []);

    const handleRowClick = (advice) => {
        navigate(`/advices/${advice.id}`, { state: { advice } });
    };

    const handleDelete = async (adviceId) => {
        if (window.confirm("Are you sure you want to delete this advice?")) {
            try {
                await adviceService.deleteAdvice(adviceId);
                // Fetch advices again from the server after successful deletion
                fetchAdvices();
            } catch (error) {
                const errorMessage = getErrorMessage(error);
                setErrorMessage(errorMessage);
                setShowErrors(true);
            }
        }
    };

    if (isLoading) {
        return <LoadingModal show={isLoading} />;
    }

    if (productAdvices.length === 0) {
        return (
            <main className='initial-fade'>
                <p>You don't have any saved product advices yet.</p>
                <Link to="/upload-images">
                    <button>Get Started</button>
                </Link>
            </main>
        );
    }

    return (
        <div>
            <Errors
                show={showErrors}
                message={errorMessage}
                onClose={() => setShowErrors(false)}
            />
            <div className="table-container">
                <table className='initial-fade'>
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th className="mobile-hide">Should Combine</th>
                        <th className="mobile-hide">Contraindications</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {productAdvices.map(advice => (
                        <tr key={advice.id} style={{ cursor: 'pointer' }}>
                            <td>{advice.name}</td>
                            <td className="mobile-hide">{shortenText(parseAdviceDetails(advice.enhancement), 20)}</td>
                            <td className="mobile-hide">{shortenText(parseAdviceDetails(advice.contraindications), 25)}</td>
                            <td>
                                <button onClick={() => handleRowClick(advice)}>View</button>
                                <button onClick={(e) => {
                                    e.stopPropagation(); // Prevent row click event
                                    handleDelete(advice.id);
                                }}>
                                    Delete
                                </button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default ProductAdvices;
