import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { adminService } from "../../services/adminService";
import { getErrorMessage } from "../../utils/commonMethods";
import Errors from "../shared/Errors";

const EditModel = ({ selectedModel, itemToEdit, onEditSuccess }) => {
    const [formData, setFormData] = useState({});
    const [companies, setCompanies] = useState([]);
    const [brands, setBrands] = useState([]);
    const [types, setTypes] = useState([]);

    const [errorMessage, setErrorMessage] = useState('');
    const [showErrors, setShowErrors] = useState(false);

    // Fetch companies, brands, and types similar to CreateModel
    useEffect(() => {
        if (selectedModel === "Brand") {
            adminService
                .getAll("Company")
                .then(setCompanies)
                .catch((error) => console.error("Error fetching companies:", error));
        }

        if (selectedModel === "Product") {
            adminService
                .getAll("Brand")
                .then(setBrands)
                .catch((error) => console.error("Error fetching brands:", error));

            adminService
                .getAll("Type")
                .then(setTypes)
                .catch((error) => console.error("Error fetching types:", error));
        }
    }, [selectedModel]);

    useEffect(() => {
        if (itemToEdit) {
            // Initialize formData ensuring checkboxes are handled correctly
            const initialFormData = { ...itemToEdit };
            Object.keys(itemToEdit).forEach(key => {
                if (typeof itemToEdit[key] === "boolean") {
                    initialFormData[key] = itemToEdit[key];
                }
            });
            setFormData(initialFormData);
        }
    }, [itemToEdit]);

    const handleInputChange = (e, field) => {
        if (e.target.type === "checkbox") {
            setFormData({ ...formData, [field]: e.target.checked });
        } else {
            setFormData({ ...formData, [field]: e.target.value });
        }
    };

    const handleSubmit = () => {
        adminService
            .update(selectedModel, formData.id, formData)
            .then(onEditSuccess)
            .catch((error) => {
                const errorMessage = getErrorMessage(error);
                console.error(errorMessage);

                setErrorMessage(errorMessage);
                setShowErrors(true);
            });
    };

    const excludeFields = ["id", "user_id", "created_on", "modified_on", "deleted_on", "user_name", "company_name", "user_name", "type_name"];

    return (
        <div>
            <Errors show={showErrors} message={errorMessage} onClose={() => setShowErrors(false)}/>
            <h2>Edit {selectedModel}</h2>
            <Form className="form box">
                {Object.entries(formData).map(([field, value]) => {
                    if (excludeFields.includes(field)) {
                        return null;
                    }
                    const fieldType = typeof value === "boolean" ? "checkbox" : "text";
                    return (
                        <Form.Group key={field}>
                            <Form.Label>{field}</Form.Label>
                            {fieldType === "checkbox" ? (
                                <Form.Check
                                    type="checkbox"
                                    checked={value}
                                    onChange={(e) => handleInputChange(e, field)}
                                />
                            ) : field === "company_id" || field === "brand_id" || field === "type_id" ? (
                                <Form.Control
                                    as="select"
                                    value={value}
                                    onChange={(e) => handleInputChange(e, field)}
                                >
                                    {/* Default option, in case you need to prompt the user or allow a default choice */}
                                    <option value="">Select an option</option>
                                    {field === "company_id" && companies.map(company => (
                                        <option key={company.id} value={company.id}>{company.name}</option>
                                    ))}
                                    {field === "brand_id" && brands.map(brand => (
                                        <option key={brand.id} value={brand.id}>{brand.name}</option>
                                    ))}
                                    {field === "type_id" && types.map(type => (
                                        <option key={type.id} value={type.id}>{type.name}</option>
                                    ))}
                                </Form.Control>
                            ) : (
                                <Form.Control
                                    type="text"
                                    value={value || ''}
                                    placeholder={`Enter ${field}`}
                                    onChange={(e) => handleInputChange(e, field)}
                                />
                            )}
                        </Form.Group>
                    );
                })}
                <Button variant="primary" onClick={handleSubmit}>
                    Save Changes
                </Button>
            </Form>
        </div>
    );
};

export default EditModel;
