import { requestInterceptor } from "./requestInterceptor";

const serverUrl = process.env.REACT_APP_SERVER_URL;

export const adviceService = {
  baseUrl: serverUrl,

  async getAdvices() {
    try {
      const request = requestInterceptor();
      const saveResponse = await request.get(`${this.baseUrl}/advices`);

      return saveResponse.data;

    } catch (error) {
      console.error("Error fetching product advices: request error.");
      return null;
    }
  },

  async updateAdvice(adviceId, adviceData) {
    try {
      const request = requestInterceptor(); // Ensure this adds the necessary authentication headers
      const response = await request.put(
        `${this.baseUrl}/advices/${adviceId}`,
        adviceData
      );

      return response.data;

    } catch (error) {
      throw error;
    }
  },

  async deleteAdvice(adviceId) {
    try {
      const request = requestInterceptor(); // Ensure this adds the necessary authentication headers
      const response = await request.delete(`${this.baseUrl}/advices/${adviceId}`);
      return response.data; // Assuming your API returns some data on deletion success

    } catch (error) {
      console.error(`Error deleting advice with ID ${adviceId}:`, error.response ? error.response.data : "request error");
      throw error; // Rethrow to handle it in the component
    }
  },

  async sendItems(items) {
    try {
      const request = requestInterceptor();
      const response = await request.post(
        `${this.baseUrl}/receive_shown_items/`,
        {
          item_ids: Array.from(items),
        }
      );
      return response.data;

    } catch (error) {
      console.error("Error sending items: request error.");
      throw error;
    }
  },
};
