import axios from 'axios';

const serverUrl = process.env.REACT_APP_SERVER_URL;

const facebookService = {
    baseUrl: serverUrl,

    async authenticateWithToken(accessToken, setIsAuthenticated) {
        try {
            const response = await axios.post(`${this.baseUrl}/facebook-login`, {
                token: accessToken
            }, {
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                }
            });

            const { token, user } = response.data;
            localStorage.setItem("token", token);
            localStorage.setItem("user", JSON.stringify(user));
            setIsAuthenticated(true);  // Assuming the context method to set authentication state
        } catch (error) {
            console.error("Error sending Facebook token to backend:", error);
        }
    }
};

export default facebookService;
