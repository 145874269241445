import React, { useEffect, useState } from "react";

import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";

import ReactGA from 'react-ga4';

import HomePage from "./components/HomePage";
import Header from "./components/shared/Header";
import UploadImages from "./components/upload/UploadImages";
import Register from "./components/auth/Register";
import Login from "./components/auth/Login";
import TermsAndConditions from "./components/TermsAndConditions";
import AuthContext from "./contexts/AuthContext";
import { authService } from "./services/authService";
import ProductAdvices from "./components/advices/ProductAdvices";
import Blog from "./components/blogs/Blog";
import Admin from "./components/admin/Admin";
import SingleBlogPost from "./components/blogs/SingleBlogPost";
import Profile from "./components/auth/Profile";
import ProductAdviceDetails from "./components/advices/ProductAdviceDetails";
import ProfileConfirmation from "./components/auth/ProfileConfirmation";
import PasswordReset from "./components/auth/PasswordReset";
import PasswordResetRequest from "./components/auth/PasswordResetRequest";
import Contacts from "./components/Contacts";
import axios from "axios";
import {GoogleOAuthProvider} from "@react-oauth/google";

const TRACKING_ID = "G-3PEWGJ4ZWX";

const serverUrl = process.env.REACT_APP_SERVER_URL;
const googleAppId = process.env.REACT_APP_GOOGLE_APP_ID;

function TrackPageViews() {
  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });

    const pageViewData = {
      eventTime: Math.floor(Date.now() / 1000), // Current timestamp in seconds
      eventName: 'PageView',
      eventSourceURL: window.location.href,
      actionSource: 'website',
      clientUserAgent: navigator.userAgent,
    };

    axios.post(`${serverUrl}/fb-page-view`, pageViewData)
        .then(response => {
          console.log('Success:', response.data);
        })
        .catch(error => {
          console.error('Error:', error);
        });

  }, [location]);

  return null; // This component does not render anything
}


const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    // Check whether the token exists in the localStorage
    const token = authService.getToken();
    if (token) {
      // If it exists, update the isAuthenticated state to true
      setIsAuthenticated(true);
    }
    const isAdmin = authService.getAdmin();
    if (isAdmin === true) {
      setIsAdmin(true);
    }
  }, []);

  return (
      <GoogleOAuthProvider clientId='903467063418-eortn3neggbpskaakmqpgc9tl0a7khkv.apps.googleusercontent.com'>
        <AuthContext.Provider
          value={{ isAuthenticated, setIsAuthenticated, isAdmin, setIsAdmin }}
        >
          <Router>
            <Header />
            <TrackPageViews /> {/* This will track page views */}
            <div className="main-section">
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/upload-images" element={<UploadImages />} />
                <Route path="/blogs" element={<Blog />} />
                {/* Add a route for viewing a single blog post */}
                <Route path="/blogs/:id" element={<SingleBlogPost />} />
                <Route path="/advices" element={<ProductAdvices />} />
                <Route path="/advices/:id" element={<ProductAdviceDetails />} />
                <Route
                  path="/terms-and-conditions"
                  element={<TermsAndConditions />}
                />
                <Route
                    path="/contacts"
                    element={<Contacts />}
                />
                <Route path="/register" element={<Register />} />
                <Route path="/login" element={<Login />} />
                <Route path="/admin" element={<Admin />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/confirm-profile" element={<ProfileConfirmation />} />
                <Route path="/reset-password" element={<PasswordReset />} />
                <Route path="/password-reset-request" element={<PasswordResetRequest />} />
              </Routes>
            </div>
          </Router>
        </AuthContext.Provider>
      </GoogleOAuthProvider>
  );
};

export default App;
