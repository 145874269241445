import React from 'react';

const TermsAndConditions = () => (
    <main>

        <h1>Table of Contents</h1>
        <ul>
            <li><a href="#terms">Terms of Use and Conditions</a></li>
            <li><a href="#privacy">Privacy Policy</a></li>
            <li><a href="#data-deletion-request">Data Deletion Request</a></li>
            <li><a href="#google-sign-in">Google Sign-In Information Handling</a></li>
        </ul>

        <section id="terms">
            <h1>Terms of Use and Conditions</h1>
            <h2>1. Introduction</h2>
            <p>1.1 This Beauty Routine Assistant Application ("the App") is owned and operated by Brandex Bulgaria LTD, a company registered in the European Union (Bulgaria, Sofia, ul Kukush 1; 1309) ("we", "us", "our").</p>
            <p>1.2 These Terms of Use and Conditions ("Terms") govern your use of the App and form a binding contractual agreement between you, the user of the App, and us.</p>
            <p>1.3 By using the App, you acknowledge that you have read these Terms and agree to abide by them. If you do not agree to these Terms, you must not use or access the App.</p>

            <h2>2. Scope of Service</h2>
            <p>2.1 The App provides a platform for users to upload or take pictures of beauty products and receive AI-generated recommendations for product compatibility and potential beauty routines.</p>
            <p>2.2 The App is not a platform for personalized or medical advice and does not provide professional healthcare consultations.</p>
            <p>2.3 The App is intended for use only by persons who are at least 18 years of age. By using the App, you affirm that you are over the age of 18.</p>

            <h2>3. Medical Disclaimer</h2>
            <p>3.1 The App is not a healthcare or medical device provider. All content is for informational purposes only and is not a substitute for professional medical advice, diagnosis, or treatment.</p>
            <p>3.2 Always seek the advice of your physician or other qualified health provider with any questions regarding a medical condition. Never disregard professional medical advice or delay in seeking it because of something you have read or seen on the App.</p>

            <h2>4. Privacy Policy</h2>
            <p>4.1 Your use of the App is also governed by our Privacy Policy, which is incorporated by reference into these Terms. By using the App, you acknowledge and agree that internet transmissions are never completely private or secure.</p>
            <p>4.2 The App follows the principles of the General Data Protection Regulation (GDPR) established by the European Union.</p>

            <h2>5. Intellectual Property</h2>
            <p>5.1 All intellectual property rights in the proprietary elements of the App, its content, and the technology developed by us are owned by Brandex Bulgaria LTD or our licensors. This includes any unique features and original content. The App also includes material that is licensed under open-source software terms, which are respected and maintained in accordance with their respective licenses. No ownership of intellectual property rights in the open-source software is claimed by us, and nothing in these Terms operates to transfer any intellectual property rights to you.</p>
            <p>5.2 We grant you a limited, non-exclusive, revocable license to use the App for your personal use in accordance with these Terms and the terms of any applicable open-source licenses.</p>

            <h2>6. User Responsibilities</h2>
            <p>6.1 You are responsible for maintaining the confidentiality of any login information associated with your account, and for all activities that occur under your account.</p>
            <p>6.2 You agree to provide accurate and current information when creating your account and using the App, and to promptly update your information to keep it accurate and current.</p>

            <h2>7. Liability and Indemnification</h2>
            <p>7.1 We are not liable for any damages, whether direct, indirect, incidental, punitive, special, or consequential, including but not limited to loss of profits, revenue, data, or use, incurred by you or any third party, whether in an action in contract or tort, even if we have been advised of the possibility of such damages. Our liability will be limited to the extent permitted by law.</p>
            <p>7.2 You agree to defend, indemnify, and hold harmless Brandex Bulgaria LTD and our affiliates, officers, agents, employees, contractors, and licensors from any claims, damages, obligations, losses, liabilities, costs, or debt, and expenses arising from: (i) your use of and access to the App; (ii) your violation of any term of these Terms; or (iii) your violation of any third-party right, including without limitation any copyright, privacy, or property right. This indemnification obligation will survive the termination of these Terms and your use of the App.</p>

            <h2>8. Applicable Law and Jurisdiction</h2>
            <p>8.1 Before any formal legal proceedings, we encourage you to contact us directly to seek a resolution via alternative dispute resolution methods such as mediation or arbitration. This can provide a more expedient and cost-effective resolution to disputes related to these Terms or your use of the App.</p>
            <p>8.2 Should these alternative dispute resolution efforts fail, these Terms are governed by the laws of the European Union, and you and we agree to submit to the exclusive jurisdiction of the Bulgarian courts to resolve any disputes arising out of these Terms or the App.</p>

            <h2>9. Changes to the Terms</h2>
            <p>9.1 We may change these Terms at any time, and we encourage you to review them periodically. Your continued use of the App indicates your acceptance of any changes.</p>

            <h2>10. Acceptance of Terms</h2>
            <p>10.1 By accessing and using the App, you signify your acceptance to be bound by these Terms. If you do not agree with these Terms, you must discontinue your use of the App.</p>

            <h2>11. Termination and Suspension</h2>
            <p>11.1 We reserve the right to suspend or terminate your account and deny access to the App without notice for any reason, including but not limited to the following:</p>
            <ul>
                <li>11.1.1 Violation of these Terms, including unauthorized use of the App.</li>
                <li>11.1.2 Attempt to harm our service or interfere with our network security.</li>
                <li>11.1.3 Failure to pay for any of the App’s paid features (if applicable).</li>
                <li>11.1.4 Engagement in fraudulent or illegal activities through or in connection with the App.</li>
                <li>11.1.5 Providing false or misleading information during account creation or update.</li>
            </ul>
            <p>11.2 Upon suspension or termination, you may lose access to any data associated with your account, including any content or features within the App.</p>
            <p>11.3 If your account has been suspended or terminated, you are prohibited from registering and creating a new account under your name, a fake or borrowed name, or the name of any third party, even if you may be acting on behalf of the third party.</p>
            <p>11.4 In addition to terminating or suspending your account, we reserve the right to take appropriate legal action, including without limitation pursuing civil, criminal, and injunctive redress.</p>

            <p>Remember that the information in these Terms is designed to help you understand your responsibilities as a user. It is not exhaustive and does not constitute legal advice. Always consult a lawyer for personal advice.</p>
        </section>

        <section id="privacy">
            <h1>Privacy Policy</h1>

            <h2>I. WHO WE ARE</h2>
            <p>"BRANDEX BULGARIA" Ltd. /we, us/, with Bulstat 130371959, headquartered at 1 Kukush Str., Sofia, 1345, Bulgaria, is a data controller under Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC (General Data Protection Regulation /GDPR/). As a data controller, we undertake to process and protect your personal data as an individual in accordance with the requirements of the GDPR and this privacy policy. Please read it carefully and familiarize yourself with your rights.</p>

            <h2>II. DATA COLLECTION</h2>
            <p>You provide us with your data:</p>
            <ul>
                <li><strong>When visiting our website:</strong> When you visit our website, certain data from the device you are using is automatically sent to our website server through cookies, such as your Internet Protocol (“IP”) address, browser type and language, and the date and time of your visit, which helps us improve the functionality and efficiency of our website without directly identifying you or drawing conclusions about you and your identity.</

li>
                <li><strong>When contacting us:</strong> When you contact us via email or the contact form on our website, we use the email and other information provided by you, as well as any additional information you voluntarily provide in your inquiry, solely to respond to your inquiry.</li>
                <li><strong>When subscribing to our newsletter:</strong> When you subscribe to our newsletter, you voluntarily consent to us using your email solely for the purpose of sending you our newsletter with our latest offers.</li>
                <li><strong>For the purpose of providing services by us:</strong> When you enter into a contractual relationship with us, including when registering as a user on our online store and ordering products, you voluntarily provide us with your personal data and consent to its use so that we can provide you with our services, as well as to comply with legal requirements imposed on us.</li>
            </ul>

            <h2>III. TYPES OF PERSONAL DATA WE PROCESS</h2>
            <p>In order to provide you with our services, comply with our legal obligations, and enhance your experience, we collect and process the following categories of personal data about you:</p>
            <ul>
                <li><strong>Basic Identity Information:</strong> Includes your name, surname, gender, and age.</li>
                <li><strong>Contact Information:</strong> Includes your email address, phone number, and postal address.</li>
                <li><strong>Digital Information:</strong> Includes data automatically collected during your use of our website, such as your Internet Protocol (IP) address, browser type, language, and the date and time of your visits.</li>
                <li><strong>Additional Voluntary Information:</strong> Any other information you choose to provide us which may include personal preferences, feedback, or inquiries to help us personalize your experience and improve our services.</li>
            </ul>

            <h2>IV. LEGAL BASIS FOR PROCESSING YOUR PERSONAL DATA</h2>
            <p>We process your personal data on one of the following legal bases:</p>
            <ul>
                <li>Based on your consent, including sending you our newsletter /Art. 6(1)(a) of the GDPR/.</li>
                <li>For the performance of a contract we have with you, including as a user in our online store, or for taking steps at your request prior to entering into a contract with you /Art. 6(1)(b) of the GDPR/.</li>
                <li>For compliance with our legal obligations /Art. 6(1)(c) of the GDPR/.</li>
                <li>When processing is necessary for the purposes of the legitimate interests pursued by us or by a third party, except where such interests are overridden by your interests or fundamental rights and freedoms which require protection of personal data, in particular if you are a child /Art. 6(1)(f) of the GDPR/.</li>
            </ul>

            <h2>V. PURPOSES OF USING PROVIDED INFORMATION</h2>
            <p>We collect, process, and use the information provided by you for the following purposes:</p>
            <ol>
                <li><strong>To provide the services you've engaged us for:</strong> Including communicating with you and informing you about the progress of your order or inquiry. Failure to provide necessary information for order/inquiry fulfillment may result in the inability to provide our services.</li>
                <li><strong>To fulfill our legal obligations:</strong> Including accounting, tax, labor, and social law obligations, as imposed by law.</li>
                <li><strong>To protect our legitimate interests:</strong> Including preparation, exercise, and defense of administrative and legal proceedings, and when there is no basis to consider that your right to data privacy overrides our legitimate interests.</li>
                <li><strong>To send you our newsletter based on your consent:</strong> You can withdraw your consent at any time by contacting us at contact@brandex.bg.</li>
                <li><strong>To maintain and improve our website and optimize its usability for your convenience and benefit:</strong> Our website uses cookies to make it work more efficiently and to be more convenient and useful to you, including ensuring its security and protection against malicious attacks.</li>
            </ol>

            <h3>Cookies</h3>
            <p>As mentioned above in Section II, 1., our website uses cookies:</p>
            <ul>
                <li><strong>What are cookies?</strong> Cookies are small text files, mostly letters and numbers, that are placed and stored on your browser or device when you visit a website, including ours.</li>
                <li><strong>How we use cookies:</strong> We use cookies to enhance the functionality of our website, improve its efficiency, and increase its usability. This includes ensuring the security of our website and protecting it from malicious attacks.</li>
            </ul>

            <p><strong>Third-party cookies:</strong> We partner with third-party services like Facebook and Google to analyze website traffic, measure performance, manage advertising, and facilitate user login processes. These services may place their own cookies on your device, which are used as follows:</p>
            <ul>
                <li><strong>Google Analytics:</strong> Assists in monitoring our website traffic and analyzing user interactions to optimize our service delivery. Google Analytics does not collect personally identifying information, such as IP addresses.</li>
                <li><strong>Facebook and Google Login:</strong> Provide options for user authentication, allowing users to sign in using their Facebook or Google account credentials, which simplifies the login process.</li>
                <li><strong>Advertising:</strong> Both Google and Facebook may use cookies to deliver targeted advertising that is relevant to your interests, enhancing your online experience and providing advertisements that are most likely to be of interest to you.</li>
            </ul>

            <h3>Controlling Cookies</h3>
            <p>Although most browsers are set by default to accept cookies automatically, you can control, including block and/or delete cookies whenever you want through your browser settings. More information on setting cookies in some of the most used browsers can be found as follows:</p>
            <ul>
                <li>For Chrome – <a href="https://support.google.com/chrome/answer/95647?hl=en">here</a></li>
                <li>For Mozilla Firefox – <a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences">here</a></li>
                <li>For Internet Explorer – <a href="https://support.microsoft.com/en-us/topic/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d">here</a></li>
                <li>For Safari – <a href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac">here</a></li>
                <li>For iOS – <a href="https://support.apple.com/en-us/HT201265">here</a></li>
                <li>For Opera – <a href="https://help.opera.com/en/latest/security-and-privacy/#clearBrowsingData">here</a></li>
            </ul>

            <p>By using our website without changing your browser settings, you agree to the use of cookies from our website.</p>
            <p><strong>Note:</strong> Deleting or blocking cookies may affect the functionality of our website, so we recommend against doing so when using our site.</p>

            <h2>VI. DISCLOSURE OF YOUR PERSONAL DATA</h2>
            <p>We may disclose your personal data to third-party data processors engaged by us, who will process them on our instructions and behalf, in fulfillment of our activities and legal obligations, aiming to achieve the processing purposes. Our third-party processors, including Cloudflare, Facebook, and Google, are carefully selected and trusted to handle your data securely and in accordance with legal requirements.</p>
            <p>Your personal data will not be disclosed by us to third parties except in the following scenarios:</p>
            <ul>
                <li>Based on your consent (Article 6, paragraph 1, letter a of the GDPR).</li>
                <li>For the performance of a contract we have entered into with you, or to take pre-contractual steps at your request (Article 6, paragraph 1, letter b of the GDPR).</li>
                <li>For compliance with our legal obligations (Article 6, paragraph 1, letter c of the GDPR).</li>
                <li>When processing is necessary for the purposes of our legitimate interests, such as for the preparation, exercise, and defense of legal claims, or when your rights do not override these interests (Article 6, paragraph 1, letter f of the GDPR).</li>
            </ul>
            <p>In addition to these reasons, we may also share your data with distributors, agents, subcontractors, suppliers, or our colleagues to provide you with our services. Specific third parties like Cloudflare are used primarily for website security and performance, Google for analytics and advertising services, and Facebook for social media interaction and advertising.</p>

            <h2>VII. DURATION OF PROCESSING YOUR PERSONAL DATA</h2>
            <p>We process and store your personal data only for as long as necessary to achieve the purposes for which we collected them and you provided them to us – providing our services, unless we are required by law to keep them for a longer period, after which we destroy them.</p>
            <p>For the purpose of sending our newsletter, we will use your email until you opt out of receiving our newsletter

, as explained above in Section V., point 4.</p>

            <h2>VIII. YOUR RIGHTS</h2>
            <p>According to the GDPR, you have the following rights:</p>
            <ol>
                <li><strong>Right of access (Article 15 of the GDPR):</strong> You have the right to obtain from us confirmation as to whether or not your personal data are being processed, and, if so, to access the following information...</li>
                <li><strong>Right to rectification (Article 16 of the GDPR):</strong> You have the right to request from us the rectification of inaccurate personal data concerning you.</li>
                <li><strong>Right to erasure ("right to be forgotten") (Article 17 of the GDPR):</strong> You have the right to request the erasure of your personal data from us, unless their storage is necessary for...</li>
                <li><strong>Right to restriction of processing (Article 18 of the GDPR):</strong> You have the right to request from us the restriction of processing where one of the following applies...</li>
                <li><strong>Right to data portability (Article 20 of the GDPR):</strong> You have the right to receive your personal data from us in a structured, commonly used and machine-readable format and have the right to transmit those data to another controller where the processing is based on consent or on a contract with you and the processing is carried out by automated means.</li>
                <li><strong>Right to object (Article 21 and subsequent of the GDPR):</strong> You have the right to object to the processing of your personal data based on Article 6(1)(e) or (f) of the GDPR, including profiling...</li>
                <li><strong>Right to withdraw your consent (Article 7(3) of the GDPR):</strong> You may withdraw your consent to the processing of your personal data by us at any time...</li>
                <li><strong>Right to lodge a complaint with a supervisory authority (Article 77 of the GDPR):</strong> For Bulgaria, the supervisory authority is the Commission for Personal Data Protection, more information about it can be found <a href="https://www.cpdp.bg/">here</a>.</li>
            </ol>

            <p>To exercise your rights, you can contact us at contact@brandex.bg or at the address Sofia, 1345, 1 Kukush Str., Republic of Bulgaria. We will endeavor to provide you with information based on your requests within 30 days of receiving your written request, aiming to respond to your requests regarding the above-mentioned rights free of charge. However, if your requests are obviously unfounded or excessive, particularly because of their repetitive character, we may impose a reasonable fee to cover administrative costs for their processing and provide you with information.</p>

            <h2>IX. HOW WE PROTECT YOUR PERSONAL DATA</h2>
            <p>To ensure that your personal data is processed in accordance with the GDPR, we have implemented adequate technical and organizational measures to ensure the protection of your personal data from accidental or unlawful destruction and from unauthorized alteration, disclosure, or access.</p>

            <h2>X. LINKS TO THIRD-PARTY WEBSITES</h2>
            <p>Our website and the information and messages sent to you by email from us may sometimes contain links to third-party websites or our profiles on social networks and sharing platforms. The personal data you provide through your visit to these websites, social networks, and sharing platforms are not subject to this privacy policy, and the processing of your personal data by these websites, social networks, and sharing platforms is not our responsibility.</p>
            <p>If you follow the link to other websites, social networks, and sharing platforms, please note that these websites and sharing platforms have their own privacy policies that determine how your information is collected and processed when you visit these websites, social networks, and sharing platforms.</p>

            <h2>XI. CHANGES TO OUR PRIVACY POLICY</h2>
            <p>All changes we make to our privacy policy will be published on our website and thus made available to you.</p>

        </section>

        <section id="data-deletion-request">
            <h1>Data Deletion Request</h1>
            <p>If you wish to delete your data that is stored by our app, please follow the instructions below:</p>

            <h2>Contact Support:</h2>
            <p>Send an email to our support team at <a href="office@beautiz.io">office@beautiz.io</a> with the subject line "Data Deletion Request".</p>
            <p>Include your full name and the email address associated with your account in the body of the email.</p>

            <h2>Processing Your Request:</h2>
            <p>Our support team will verify your identity and process your request within 30 days.</p>
            <p>You will receive a confirmation email once your data has been successfully deleted.</p>

            <h2>Additional Assistance:</h2>
            <p>If you have any questions or need further assistance, please contact our support team at <a href="mailto:office@beautiz.io">office@beautiz.io</a>.</p>

            <p>We take your privacy seriously and are committed to ensuring your data is handled securely and deleted upon request.</p>
        </section>

        <section id="google-sign-in">
            <h1>Google Sign-In Information Handling</h1>
            <p>We handle information from Google Sign-Ins to facilitate the login or creation of user accounts. Here is how we manage your Google Sign-In information:</p>

            <h2>Account Creation and Login</h2>
            <p>When you use Google Sign-In to create or log into your account, we receive certain information from Google, such as your email address, name, and surname. This information is used solely for the purposes of creating your account and providing access to our services.</p>

            <h2>Token Management</h2>
            <p>We securely handle and dispose of the tokens used during the Google Sign-In process. Tokens are not stored or used beyond the initial authentication process.</p>

            <h2>Data Privacy</h2>
            <p>Your email and other information obtained through Google Sign-In are not disclosed to any third parties, except as necessary to provide our services or comply with legal obligations. We ensure that all data is handled in accordance with our privacy policy and the GDPR.</p>
        </section>

        <p><strong>Last updated:</strong> 27/04/2024</p>

    </main>
);

export default TermsAndConditions;
