import {GENERIC_ERROR_MESSAGE} from "./constants";

export const trimText = (text, limit) => text.length > limit ? `${text.substring(0, limit)}...` : text;

export const getErrorMessage = (error) => {
    return error.response && error.response.data && error.response.data.detail
        ? error.response.data.detail
        : GENERIC_ERROR_MESSAGE;
};

export const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    // Month needs +1 because getMonth() returns 0-11
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
};
